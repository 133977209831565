let country = {
    "code": 260,
    "exitCode": "00260",
    "trunkPrefix": 0,
    "nsn": 9
}

const phoneNumber = (phone) => {

    if (!phone) {
        return "";
    }
    let {code, exitCode, trunkPrefix} = country;
    let countCode = code.toString().length;
    let countExitCode = exitCode.toString().length;
    let countExtraCode = 1 + code.toString().length;
    let countTrunkPrefix = trunkPrefix.toString().length;

    if (phone.substring(0, countCode) == code) {
        return phone;
    } else if (phone.substring(0, countExitCode) == exitCode) {
        return phone.replace(/00/i, '');
    } else if (phone.substring(0, countTrunkPrefix) == trunkPrefix) {
        return phone.replace(/0/i, code);
    } else if (phone.substring(0, countExtraCode) == "+" + code) {
        return phone.replace(/[\+]/i, '');
    } else {
        return null;
    }
}

export default phoneNumber;
