import { DOWNLOAD_REPORTS, GET_ALL_INSURANCE_URL, GET_REPORTS } from "../api";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

export const useDownloadReports = (from, to, insuranceCompanyId) => {
  const fromDate = from !== undefined ? moment(from).format() : null;
  const toDate = to !== undefined ? moment(to).format() : null;

  if (insuranceCompanyId === "null") {
    insuranceCompanyId = null;
  }
  return useQuery(
    ["downloads-report"],
    async () => {
      return axios
        .get(DOWNLOAD_REPORTS(), {
          params: {
            insuranceCompanyId,
            toDate,
            fromDate,
          },
          responseType: "blob",
        })
        .then((response) => {
          console.log("report download response", response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `gariReport${moment().format("DD-MM-YYYY")}.csv`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          return response.data.data;
        });
    },
    { enabled: false, refetchOnWindowFocus: false }
  );
};
export const useGetReports = (from, to, insuranceCompanyId) => {
  const fromDate = from !== undefined ? moment(from).format() : null;
  const toDate = to !== undefined ? moment(to).format() : null;

  if (insuranceCompanyId === "null" || insuranceCompanyId === "All") {
    insuranceCompanyId = null;
  }
  console.log("dates", to, from);

  console.log("insurance id", insuranceCompanyId);
  return useQuery(["reports"], async () => {
    return axios
      .get(GET_REPORTS(), {
        params: {
          insuranceCompanyId,
          toDate,
          fromDate,
        },
      })
      .then((response) => {
        // console.log("report response", response.data.data.length);
        return response.data.data;
      });
  });
};
export const useGetInsuranceCompanies = () => {
  return useQuery(["insuranceCompanies"], async () => {
    return axios.get(GET_ALL_INSURANCE_URL).then((response) => {
      return response.data.data;
    });
  });
};
