import React from "react";
import PrivateRoute from "../../components/common/PrivateRoute";
import { Switch, Route } from "react-router-dom";
import PageError from "../../components/layout/PageError";
import Dashboard from "../../pages/authorized/Dashboard";
import Products from "../../pages/authorized/product/Products";
import MotorQuotation from "../../pages/authorized/quotation/MotorQuotation";
import Policy from "../../pages/authorized/underwriting/Policy";
import PolicyDetails from "../../pages/authorized/underwriting/PolicyDetails";
import ClientDetails from "../../pages/authorized/client/ClientDetails";
import QuotationDetails from "../../pages/authorized/quotation/QuotationDetails";
import ProductDetails from "../../pages/authorized/product/ProductDetails";
import Receipt from "../../pages/authorized/finance/Receipt";
import A4 from "../../pages/authorized/finance/A4";
import ClientFound from "../../pages/authorized/client/ClientFound";
import Discount from "../../pages/authorized/addons/Discount";
import Extension from "../../pages/authorized/addons/Extension";
import Loading from "../../pages/authorized/addons/Loading";
import Claim from "../../pages/authorized/claim/Claim";
import RiskCategory from "../../pages/authorized/product/RiskCategory";
import RiskCategoryDetails from "../../pages/authorized/product/RiskCategoryDetails";
import RTSAUpload from "../../pages/authorized/underwriting/RTSAUpload";
import Endorsement from "../../pages/authorized/underwriting/Endorsement";
import Client from "../../pages/authorized/client/Client";
import ClaimDetails from "../../pages/authorized/claim/ClaimDetails";
import Salvages from "../../pages/authorized/salvages/Salvages";
import SalvagesDetails from "../../pages/authorized/salvages/SalvageDetails";
import { Reports } from "../../pages/authorized/reports/Reports";
import Agent from "../../pages/authorized/agent/Agent";
import AgentDetails from "../../pages/authorized/agent/AgentDetails";

const Private = () => {
  return (
    <div>
      <Switch>
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/client" component={Client} />
        <PrivateRoute
          exact
          path="/client-details/:id"
          component={ClientDetails}
        />
        <PrivateRoute exact path="/endorsement" component={Endorsement} />
        <PrivateRoute exact path="/client-found" component={ClientFound} />
        <PrivateRoute
          exact
          path="/motor-quotation"
          component={MotorQuotation}
        />
        <PrivateRoute
          exact
          path="/quotation-details/:id"
          component={QuotationDetails}
        />
        <PrivateRoute exact path="/policy" component={Policy} />
        <PrivateRoute exact path="/rtsa-upload" component={RTSAUpload} />
        <PrivateRoute exact path="/claim" component={Claim} />
        <PrivateRoute
          exact
          path="/policy-details/:id/:_id"
          component={PolicyDetails}
        />
        <PrivateRoute
          exact
          path="/claim-details/:claim"
          component={ClaimDetails}
        />
        <PrivateRoute exact path="/agent" component={Agent} />
        <PrivateRoute
          exact
          path="/agent-details/:id"
          component={AgentDetails}
        />
        <PrivateRoute exact path="/salvage" component={Salvages} />
        <PrivateRoute
          exact
          path="/salvage-details/:id"
          component={SalvagesDetails}
        />
        <PrivateRoute exact path="/receipt" component={Receipt} />
        <PrivateRoute exact path="/report" component={Reports} />
        <PrivateRoute exact path="/a4/:receipt" component={A4} />
        <PrivateRoute exact path="/error" component={PageError} />
        <PrivateRoute exact path="/product" component={Products} />
        <PrivateRoute exact path="/discount" component={Discount} />
        <PrivateRoute exact path="/extension" component={Extension} />
        <PrivateRoute exact path="/loading" component={Loading} />
        <PrivateRoute exact path="/product" component={Products} />
        <PrivateRoute
          exact
          path="/product-details/:id"
          component={ProductDetails}
        />
        <PrivateRoute exact path="/risk-category" component={RiskCategory} />
        <PrivateRoute
          exact
          path="/risk-category-list/:id"
          component={RiskCategoryDetails}
        />
        <Route component={PageError} />
      </Switch>
    </div>
  );
};

export default Private;
