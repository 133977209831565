import React, { useState } from "react";
import $ from "jquery";
import "components-jqueryui";
import "parsleyjs";
import "select2";
import "datatables.net";
import "datatables.net-responsive";
import cogoToast from "cogo-toast";
import { useSelector } from "react-redux";
import axios from "axios";

const RSTAUpload = (props) => {
  document.title = "RTSA Upload";

  const { id } = useSelector((state) => state.auth.user.user);

  const [type, setType] = useState("2");
  const [basicLoading, setBasicLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //     getClientStatistics().then((response) => {
  //         $('#clients').text(response);
  //     });
  //
  //     var table = $('#td-insurance').DataTable({
  //         responsive: true,
  //         // language: {
  //         //     processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Policies Data</p>'
  //         // },
  //         searching: false,
  //         lengthMenu: [[10], [10]],
  //         ordering: false,
  //         info: true,
  //         bFilter: false,
  //         processing: false,
  //         pageLength: 10,
  //         serverSide: false
  //     });
  //
  //     $('#td-insurance tbody').on('click', 'tr', function () {
  //         var extract_data = table.row(this).data();
  //         props.history.push(`/policy-details/${extract_data.raw.clientId}/${encodeQueryParameter(extract_data.raw.id)}`);
  //     } );
  //
  //     return () => {
  //         $("#date_from").datepicker("destroy");
  //     }
  //
  // }, []);

  $(function () {
    $("#date_from").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });

    $("#date_to").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });

  const handleShowModal = () => {
    $("#basicRTSA").parsley();
    $("#modalAddDocument").modal("show");
  };

  const onDocumentSubmit = (e) => {
    e.preventDefault();

    // setLoading(true);

    let raw = [
      {
        insuranceType: $("#insuranceType").val(),
        status: $("#status").val(),
        registrationMark: $("#RegNumber").val(),
        dateFrom: new Date($("#date_from").val()),
        dateTo: new Date($("#date_to").val()),
        insurancePolicyNo: $("#policyNo").val(),
        chassisNumber: $("#chassisNumber").val(),
      },
    ];

    const token = Buffer.from(`mwangala.mwiya:picz@2019`, "utf8").toString(
      "base64"
    );

    axios
      .post(
        "https://zampointzidb.eservices.gov.zm/ZIDB/ReceiveInsurancePolicies",
        raw,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Basic ${token}`,
            Host: "dashboard.gari.dev",
          },
        }
      )
      .then((res) => {
        console.log(res);
        cogoToast.success("Something went wrong", { position: "top-right" });
      })
      .catch((error) => {
        console.log(error);
        cogoToast.error("Something went wrong", { position: "top-right" });
      });
  };

  const onCloseModal = (id, form) => {
    let parsley = $(`#${form}`).parsley();
    $(":input", `#${form}`)
      .not(":button, :submit, :reset, :hidden")
      .val("")
      .prop("checked", false)
      .prop("selected", false);
    $(`#${id}`).modal("hide");
    parsley.reset();
    parsley.destroy();
    $(".modal-backdrop").remove();
  };

  return (
    <div className="content-body">
      <div className="container pd-x-0">
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                <li className="breadcrumb-item">Menu</li>
                <li className="breadcrumb-item">Policy</li>
                <li className="breadcrumb-item active" aria-current="page">
                  RTSA Upload
                </li>
              </ol>
            </nav>
            <h4 className="mg-b-0 tx-spacing--1">RTSA Upload</h4>
          </div>
          <div className="d-none d-md-block">
            <button
              className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"
              onClick={handleShowModal.bind()}
            >
              <i className="wd-10 mg-r-5 fa fa-plus"></i> Upload Single Policy
            </button>
            <button
              className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"
              onClick={handleShowModal.bind()}
            >
              <i className="wd-10 mg-r-5 fa fa-plus"></i> Upload Multiple
              Policies
            </button>
          </div>
        </div>

        {/*<div className="row row-xs">*/}
        {/*    <div className="col-md-12 mg-t-10">*/}
        {/*        <div className="card">*/}
        {/*            <div className="card-header d-flex align-items-center justify-content-between">*/}
        {/*                <h6 className="mg-b-0">RTSA Document Upload Lists</h6>*/}
        {/*                <div className="d-flex tx-18">*/}
        {/*                    <a href="" className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="card-body table-responsive">*/}
        {/*                <table id="td-insurance" className="table table-hover">*/}
        {/*                    <thead className="thead-light">*/}
        {/*                    <tr>*/}
        {/*                        <th className="wd-10p">Registration Mark</th>*/}
        {/*                        <th className="wd-10p">Insurance Policy #</th>*/}
        {/*                        <th className="wd-10p">Insurance Type</th>*/}
        {/*                        <th className="wd-10p">Start Date</th>*/}
        {/*                        <th className="wd-10p">End Date</th>*/}
        {/*                        <th className="wd-10p">Status</th>*/}
        {/*                        <th className="wd-10p">Chassis Number</th>*/}
        {/*                    </tr>*/}
        {/*                    </thead>*/}
        {/*                    <tbody>*/}
        {/*                        <tr>*/}
        {/*                            <td>AIB 3779</td>*/}
        {/*                            <td>305/TBA/2021/0000015</td>*/}
        {/*                            <td>Motor Third Party</td>*/}
        {/*                            <td>30/06/2021</td>*/}
        {/*                            <td>30/12/2021</td>*/}
        {/*                            <td>Active</td>*/}
        {/*                            <td>ANH10-0019912</td>*/}
        {/*                        </tr>*/}
        {/*                    </tbody>*/}
        {/*                </table>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <div
          className="modal fade"
          id="modalAddDocument"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered wd-sm-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a
                  href="#"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </a>
                <div className="media-body">
                  <a
                    href="javascript:void(0)"
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    onClick={onCloseModal.bind(
                      null,
                      "modalAddDocument",
                      "basicRTSA"
                    )}
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <h4 className="tx-18 tx-sm-20 mg-b-2">Upload Document</h4>
                  <p className="tx-13 tx-color-02 mg-b-0">
                    Fill the information below.
                  </p>
                </div>
              </div>
              <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                <form
                  id="basicRTSA"
                  className="parsley-style-1"
                  onSubmit={onDocumentSubmit.bind()}
                >
                  <div className="row row-sm">
                    <div
                      id="insuranceWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Insurance Type<span className="tx-danger">*</span>
                      </label>
                      <select
                        className="custom-select"
                        id="insuranceType"
                        required
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value="0">Comprehensive</option>
                        <option value="1">Motor Third Party</option>
                      </select>
                    </div>

                    <div
                      id="statusWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Status<span className="tx-danger">*</span>
                      </label>
                      <select className="custom-select" id="status" required>
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value="0">Expired</option>
                        <option value="1">Active</option>
                      </select>
                    </div>

                    <div
                      id="regWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Registration Mark#<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="RegNumber"
                        className="form-control"
                        placeholder="Enter registration number"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#regWrapper"
                        required
                      />
                    </div>

                    <div
                      id="insurancePolicyNoWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Insurance Policy #<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="policyNo"
                        className="form-control"
                        placeholder="Enter insurance Policy #"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#insurancePolicyNo"
                        required
                      />
                    </div>

                    <div
                      id="startDateWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Date From<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="date_from"
                        className="form-control"
                        placeholder="Select Start Date"
                        autoComplete="off"
                        data-parsley-class-handler="#startDateWrapper"
                        required
                        readOnly
                      />
                    </div>

                    <div
                      id="endDateWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Date To<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="date_to"
                        className="form-control"
                        placeholder="Select End Date"
                        autoComplete="off"
                        data-parsley-class-handler="#endDateWrapper"
                        required
                        readOnly
                      />
                    </div>

                    <div
                      id="chassisWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Chassis Number<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="chassisNumber"
                        className="form-control"
                        placeholder="Enter chassis number"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#chassisWrapper"
                        required
                      />
                    </div>
                  </div>
                  {loading ? (
                    <button disabled className="btn btn-brand-02 btn-block">
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        role="status"
                        aria-hidden="true"
                      />
                      Please Wait
                    </button>
                  ) : (
                    <button className="btn btn-brand-02 btn-block mt-2">
                      <i className="ion-md-add" /> Submit
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RSTAUpload;
