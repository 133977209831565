import React, { useEffect, useState } from 'react';
import {GET_POLICIES_URL} from '../../../api';
import {getClientStatistics, verifyPhoneNumber} from '../../../request';
import $ from 'jquery';
import 'components-jqueryui';
import 'parsleyjs';
import 'select2';
import 'datatables.net';
import 'datatables.net-responsive';
import countries from '../../../data/countries.json';
import cogoToast from "cogo-toast";
import moment from "moment";
import {encodeQueryParameter} from "../../../utils/url";
import {useSelector} from "react-redux";

const Policy = (props) => {

    document.title = "Policy";

    const {id} = useSelector((state) => state.auth.user.user);

    const [type, setType] = useState("2");
    const [basicLoading, setBasicLoading] = useState(false);

    useEffect(() => {
        getClientStatistics(id).then((response) => {
            $('#clients').text(response);
        });

        var table = $('#policy').DataTable({
            responsive: true,
            language: {
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Policies Data</p>'
            },
            searching: false,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(`${GET_POLICIES_URL(id)}`, {
                    type: 'GET',
                    data: {
                        pageNumber: (data.length + data.start) / data.length,
                        pageSize: data.length
                    },
                    success : function(res) {
                        let result = [];

                        res.data.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.totalRecords,
                            recordsFiltered: res.totalRecords,
                            data: result
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        console.log(data);
                        return data.policyNumber;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${data.client.firstName} ${data.client.middleName ? data.client.middleName : ""} ${data.client.lastName}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return moment(data.startDate).add(1,'days').format("Do MMM, YYYY");
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.endDate).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.startDate).format("YYYY");
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return {
                            "Active" : `<span class="badge badge-success tx-white">${data.status}</span>`,
                            "Expired" : `<span class="badge badge-warning tx-white">${data.status}</span>`,
                            "Cancelled" : `<span class="badge badge-danger">${data.status}</span>`,
                            "InClaim" : `<span class="badge badge-warning">${data.status}</span>`,
                            "InReview" : `<span class="badge badge-info">${data.status}</span>`,
                            "PayLater" : `<span class="badge badge-secondary">${data.status}</span>`
                        }[data.status];
                    },
                    "targets": 5
                }
            ]
        });

        $('#policy tbody').on('click', 'tr', function () {
            var extract_data = table.row(this).data();
            props.history.push(`/policy-details/${extract_data.raw.clientId}/${encodeQueryParameter(extract_data.raw.id)}`);
        } );

        return () => {
            $("#date_from").datepicker("destroy");
        }

    }, []);

    $(function () {
        $('#date_from').datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            minDate: '-120Y',
            maxDate: '-18Y',
            yearRange: "1800:+nn",
            changeMonth: true,
            changeYear: true,
            defaultDate: '+1w',
            numberOfMonths: 1,
            dateFormat: 'yy/mm/dd'
        });

        $('.ad-select2').select2({
            minimumResultsForSearch: Infinity,
            placeholder: 'Select'
        });

        $('#category_data').select2({
            placeholder: "Please select an skill",
            allowClear: true
        });
    });

    const handleShowModal = () => {
        $("#modalAddUser").modal("show");
    };

    const handleCientSubmit = (e) => {
        e.preventDefault();

        setBasicLoading(true);

        verifyPhoneNumber($("#phoneNumber").val()).then((res) => {
            setBasicLoading(false);

            const options = {
                position: "top-right"
            };

            if(res.error) return cogoToast.error(res.payload.errors[0], options);

            $('#nav_basic .active').parent().next('li').find('a').trigger('click');
        });
    };

    const handleResetForm = ()=> {

    };

    const onTypeChange = (e) => {
        $('#basicInfo').parsley().reset();
        setType(e.target.value);
    };

    const handlePrevious = () => {
        $('#nav_basic .active').parent().prev('li').find('a').trigger('click');
    };

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Policy</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Policy</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Policy</h6>
                                <div className="d-flex tx-18">
                                    <a href="" className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="policy" className="table table-hover">
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="wd-20p">Policy #</th>
                                        <th className="wd-20p">Client Name</th>
                                        <th className="wd-15p">Start Date</th>
                                        <th className="wd-15p">End Date</th>
                                        <th className="wd-10p">U/W Year</th>
                                        <th className="wd-10p">Status</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalAddUser" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={handleResetForm.bind()} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Create User</h4><p className="tx-13 tx-color-02 mg-b-0">Create user to have access to the app</p></div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium nav-disabled">
                                        <li className="nav-item"><a href="#basic" className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle"/> Basic Info</a></li>
                                        <li className="nav-item"><a href="#bank" className="nav-link" data-toggle="tab"><i className="ion-md-card"/> Bank Info</a></li>
                                        <li className="nav-item"><a href="#confirmation" className="nav-link" data-toggle="tab"><i className="ion-ios-checkmark-circle"/> Confirmation</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="basic" className="tab-pane fade active show">
                                    <form id="basicInfo" className="parsley-style-1" data-parsley-validate noValidate onSubmit={handleCientSubmit.bind()}>
                                        <div className="row row-sm">
                                            <div id="typeWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Type<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="type" onChange={onTypeChange.bind(null)} required>
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="0">Individual</option>
                                                    <option value="1">Corporate</option>
                                                </select>
                                            </div>
                                            {(() => {
                                                switch(type) {
                                                    case "0": return(
                                                        <>
                                                            <div id="firstWrapper" className="form-group parsley-input col-sm-4">
                                                                <label>First Name<span className="tx-danger">*</span></label>
                                                                <input id="firstName" className="form-control"
                                                                       placeholder="Enter first name"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#firstWrapper" required/>
                                                            </div>

                                                            <div id="middleWrapper" className="form-group parsley-input col-sm-4">
                                                                <label>Middle Name (Optional)</label>
                                                                <input id="name" className="form-control"
                                                                       placeholder="Enter middle name"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#middleWrapper"/>
                                                            </div>

                                                            <div id="lastWrapper" className="form-group parsley-input col-sm-4">
                                                                <label>Last Name<span className="tx-danger">*</span></label>
                                                                <input id="lastName" className="form-control"
                                                                       placeholder="Enter last name"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#lastWrapper" required/>
                                                            </div>

                                                            <div id="dateWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>Date of Birth<span className="tx-danger">*</span></label>
                                                                <input type="text"
                                                                       id="date_from"
                                                                       className="form-control"
                                                                       placeholder="Select Start Date"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#dateWrapper"
                                                                       required
                                                                       readOnly
                                                                />
                                                            </div>

                                                            <div id="genderWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>Gender<span className="tx-danger">*</span></label>
                                                                <select className="custom-select" id="gender" data-parsley-class-handler="#genderWrapper" required>
                                                                    <option value="">Select</option>
                                                                    <option value="Rent">Male</option>
                                                                    <option value="Sale">Female</option>
                                                                    <option value="Sale">Other</option>
                                                                </select>
                                                            </div>

                                                            <div id="nationalityWrapper" className="form-group parsley-input col-sm-12">
                                                                <label>Nationality<span className="tx-danger">*</span></label>
                                                                <select className="custom-select" id="nationality" data-parsley-class-handler="#nationalityWrapper" required>
                                                                    <option value="">Select</option>
                                                                    {countries.map((data, index) => <option key={index} value={data.name}>{data.name}</option>)}
                                                                </select>
                                                            </div>

                                                            <div id="idWrapper" className="form-group parsley-input col-sm-12">
                                                                <label>Marital Status<span className="tx-danger">*</span></label>
                                                                <select className="custom-select" id="marital" data-parsley-class-handler="#idWrapper" required>
                                                                    <option value="">Select</option>
                                                                    <option value="married">Married</option>
                                                                    <option value="single">Single</option>
                                                                    <option value="widow">Widow</option>
                                                                </select>
                                                            </div>

                                                            <div id="idWrapper" className="form-group parsley-input col-sm-12">
                                                                <label>ID Type<span className="tx-danger">*</span></label>
                                                                <select className="custom-select" id="idType" data-parsley-class-handler="#idWrapper" required>
                                                                    <option value="">Select</option>
                                                                    <option value="Passport">Passport</option>
                                                                    <option value="NRC">NRC</option>
                                                                </select>
                                                            </div>

                                                            <div id="idTypeValueWrapper" className="form-group parsley-input col-sm-12">
                                                                <label>Passport<span className="tx-danger">*</span></label>
                                                                <input id="idTypeValue" className="form-control"
                                                                       placeholder="Enter value"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#idTypeValueWrapper" required/>
                                                            </div>

                                                            <div id="addressWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>Address<span className="tx-danger">*</span></label>
                                                                <input id="address" className="form-control"
                                                                       placeholder="Enter address"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#addressWrapper" required/>
                                                            </div>

                                                            <div id="boxWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>P.O.Box<span className="tx-danger">*</span></label>
                                                                <input id="pobox" className="form-control"
                                                                       placeholder="Enter P.O. Box"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#boxWrapper" required/>
                                                            </div>

                                                            <div id="phoneWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>Phone Number<span className="tx-danger">*</span></label>
                                                                <input id="phoneNumber" className="form-control"
                                                                       placeholder="Enter phone number"
                                                                       type="number"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#phoneWrapper" required/>
                                                            </div>

                                                            <div id="emailWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>Email<span className="tx-danger">*</span></label>
                                                                <input id="emailAddress" className="form-control"
                                                                       placeholder="Enter last name"
                                                                       type="email"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#emailWrapper" required/>
                                                            </div>
                                                        </>
                                                    )
                                                    case "1": return (
                                                        <>
                                                            <div id="companyWrapper" className="form-group parsley-input col-sm-12">
                                                                <label>Company Name<span className="tx-danger">*</span></label>
                                                                <input id="companyName" className="form-control"
                                                                       placeholder="Enter company name"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#companyWrapper" required/>
                                                            </div>

                                                            <div id="cRegWrapper" className="form-group parsley-input col-sm-12">
                                                                <label>Company Registration #<span className="tx-danger">*</span></label>
                                                                <input id="companyReg" className="form-control"
                                                                       placeholder="Enter first name"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#cRegWrapper" required/>
                                                            </div>

                                                            <div id="cPhoneWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>Phone Number<span className="tx-danger">*</span></label>
                                                                <input id="phone" className="form-control"
                                                                       placeholder="Enter phone number"
                                                                       type="number"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#cPhoneWrapper" required/>
                                                            </div>

                                                            <div id="cEmailWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>Email<span className="tx-danger">*</span></label>
                                                                <input id="email" className="form-control"
                                                                       placeholder="Enter last name"
                                                                       type="email"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#cEmailWrapper" required/>
                                                            </div>

                                                            <div id="cAddressWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>Address<span className="tx-danger">*</span></label>
                                                                <input id="address" className="form-control"
                                                                       placeholder="Enter address"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#cAddressWrapper" required/>
                                                            </div>

                                                            <div id="cBoxWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>P.O.Box<span className="tx-danger">*</span></label>
                                                                <input id="pobox" className="form-control"
                                                                       placeholder="Enter P.O. Box"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#cBoxWrapper" required/>
                                                            </div>

                                                            <hr/>

                                                            <div className="col-md-12 mg-t-30">
                                                                <h5>Person Contact</h5>
                                                            </div>

                                                            <div id="titleWrapper" className="form-group parsley-input col-sm-12">
                                                                <label>Title<span className="tx-danger">*</span></label>
                                                                <select className="custom-select" id="titleWrapper" data-parsley-class-handler="#genderWrapper" required>
                                                                    <option value="">Select</option>
                                                                    <option value="Mr">Mr</option>
                                                                    <option value="Mrs">Mrs</option>
                                                                    <option value="Miss">Miss</option>
                                                                </select>
                                                            </div>

                                                            <div id="cFirstWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>First Name<span className="tx-danger">*</span></label>
                                                                <input id="firstName" className="form-control"
                                                                       placeholder="Enter first name"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#cFirstWrapper" required/>
                                                            </div>

                                                            <div id="lastWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>Last Name<span className="tx-danger">*</span></label>
                                                                <input id="lastName" className="form-control"
                                                                       placeholder="Enter last name"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#lastWrapper" required/>
                                                            </div>

                                                            <div id="genderWrapper" className="form-group parsley-input col-sm-12">
                                                                <label>Gender<span className="tx-danger">*</span></label>
                                                                <select className="custom-select" id="gender" data-parsley-class-handler="#genderWrapper" required>
                                                                    <option value="">Select</option>
                                                                    <option value="Rent">Male</option>
                                                                    <option value="Sale">Female</option>
                                                                    <option value="Sale">Other</option>
                                                                </select>
                                                            </div>

                                                            <div id="contactPhoneWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>Phone Number<span className="tx-danger">*</span></label>
                                                                <input id="contactNumber" className="form-control"
                                                                       placeholder="Enter phone number"
                                                                       type="number"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#contactPhoneWrapper" required/>
                                                            </div>

                                                            <div id="contactEmailWrapper" className="form-group parsley-input col-sm-6">
                                                                <label>Email<span className="tx-danger">*</span></label>
                                                                <input id="contactEmail" className="form-control"
                                                                       placeholder="Enter last name"
                                                                       type="email"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#contactEmailWrapper" required/>
                                                            </div>

                                                            <div id="designationWrapper" className="form-group parsley-input col-sm-12">
                                                                <label>Designation<span className="tx-danger">*</span></label>
                                                                <input id="designation" className="form-control"
                                                                       placeholder="Enter designation"
                                                                       type="text"
                                                                       autoComplete="off"
                                                                       data-parsley-class-handler="#designationWrapper" required/>
                                                            </div>
                                                        </>
                                                    );
                                                    default: return null;
                                                }
                                            })()}
                                        </div>
                                        {basicLoading ? <button disabled className="btn btn-brand-02 float-right"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-brand-01 float-right btnNextFront">Next <i className="ion-md-arrow-forward"/></button>}
                                    </form>
                                </div>

                                <div id="bank" className="tab-pane fade">
                                    <form id="bank_info" className="parsley-style-1" data-parsley-validate noValidate>
                                        <div className="row row-sm">
                                            <div id="accountWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Account Name<span className="tx-danger">*</span></label>
                                                <input id="accountName" className="form-control"
                                                       placeholder="Enter account name"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#accountWrapper" required/>
                                            </div>

                                            <div id="accountNumberWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Account Number<span className="tx-danger">*</span></label>
                                                <input id="accountNumber" className="form-control"
                                                       placeholder="Enter account number"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#accountNumberWrapper" required/>
                                            </div>

                                            <div id="bankNameWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Bank Name<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="bankName" required>
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="FNB">FNB</option>
                                                    <option value="Zanaco">Zanaco</option>
                                                </select>
                                            </div>

                                            <div id="bankBranchWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Bank Branch<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="bankBranchWrapper" required>
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="FNB">Chilenje</option>
                                                    <option value="Zanaco">Manda Hill</option>
                                                </select>
                                            </div>

                                            <div id="branchCodeWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Branch Code<span className="tx-danger">*</span></label>
                                                <input id="branchCode" className="form-control"
                                                       placeholder="Enter branch code"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#branchCodeWrapper" required/>
                                            </div>
                                        </div>
                                        <hr/>
                                        <button className="btn btn-brand-01 float-right btnNextAd">Next <i className="ion-md-arrow-forward"/></button>
                                    </form>
                                    <button className="btn btn-dark float-left btnPrevious" onClick={handlePrevious.bind()}><i className="ion-md-arrow-back"/> Back</button>
                                </div>

                                <div id="confirmation" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <h6 className="tx-purple">Person Details</h6>
                                            <p><strong>Account Type:</strong> Individual</p>
                                            <p><strong>Full Name:</strong> Michael Chuba</p>
                                            <p><strong>Gender:</strong> Male</p>
                                            <p><strong>Mobile Number:</strong> 0975502028</p>
                                            <p><strong>Passport:</strong> 2734/33/122</p>
                                            <p><strong>Email:</strong> michaelchuba@icloud.com</p>
                                            <p><strong>Physical Address:</strong> 27211, Libala stage 3</p>
                                            <p><strong>Marital Status:</strong> Single</p>
                                            <p><strong>Nationality:</strong> Zambian</p>
                                        </div>

                                        <div className="col-md-12">
                                            <hr/>
                                            <h6 className="tx-purple">Bank Details</h6>
                                            <p><strong>Account Name:</strong> Michael Chuba</p>
                                            <p><strong>Account Number:</strong> 27463826423</p>
                                            <p><strong>Bank Name:</strong> Zanaco</p>
                                            <p><strong>Branch Name:</strong> Manda Hill</p>
                                            <p><strong>Branch Code:</strong> 32434</p>
                                        </div>
                                    </div>

                                    <hr/>
                                    <button className="btn btn-dark float-left btnPrevious"><i className="ion-md-arrow-back"/> Back</button>
                                    <button className="btn btn-brand-01 float-right btnNextAd">Finish <i className="ion-md-arrow-forward"/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Policy;
