import axios from "axios";
import {GET_INSURANCE_ALL_PRODUCT} from "../api";

export const receiveInsurancePolicies = (id) => {
    return axios.get(GET_INSURANCE_ALL_PRODUCT(id)).then((response) => {
        return false;
    }).catch((error) => {
        console.log("Something went wrong");
    });
};
