import axios from "axios";
import {
  CREATE_AGENT_URL,
  GARI_AGENT_ID_URL,
  GARI_AGENT_STATUS_URL,
} from "../api";

export const createAgent = (raw) => {
  return axios
    .post(CREATE_AGENT_URL, raw)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      console.log("agent error", error.response);
      return {
        error: true,
        payload: error.response.data["message"],
      };
    });
};

export const updateAgent = (raw, id) => {
  return axios
    .put(GARI_AGENT_ID_URL(id), raw)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error,
      };
    });
};

export const getAgentStats = (id) => {
  return axios
    .get(GARI_AGENT_STATUS_URL(id))
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error,
      };
    });
};

export const getAgentDetails = (id) => {
  return axios
    .all([
      axios.get(GARI_AGENT_STATUS_URL(id)),
      axios.get(GARI_AGENT_ID_URL(id)),
    ])
    .then(
      axios.spread((response1, response2) => {
        return {
          payload: {
            stats: response1.data,
            agent: response2.data,
          },
          error: false,
        };
      })
    )
    .catch(() => {
      return {
        payload: null,
        error: true,
      };
    });
};

export const deleteAgent = (id) => {
  return axios
    .delete(GARI_AGENT_ID_URL(id))
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error,
      };
    });
};
