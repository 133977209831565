import React, {useEffect, useState} from 'react';
import {
    GET_CLIENT_VEHICLE_URL,
    GET_QUOTATION_URL,
    SEARCH_CLIENT_URL,
    SEARCH_CLIENT_VEHICLE_URL,
    SEARCH_QUOTATION_URL
} from '../../../api';
import $ from 'jquery';
import 'components-jqueryui';
import 'parsleyjs';
import 'select2';
import 'datatables.net';
import 'datatables.net-responsive';
import moment from "moment";
import {useSelector} from "react-redux";
import {createQuotation, createThirdOrActOnlyQuotation} from "../../../request";
import cogoToast from "cogo-toast";
import {capitalize} from "../../../utils/capitalize";

const MotorQuotation = (props) => {

    document.title = "Motor Quotation";

    const {id} = useSelector((state) => state.auth.user.user);

    let [updateVehicle, setUpdateVehicle] = useState(true);
    const [basicLoading, setBasicLoading] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [type, setType] = useState("Amount");
    const [userId, setUserId] = useState("");

    const {product} = useSelector((state) => state.organization.product);

    useEffect(() => {

        $.fn.modal.Constructor.prototype.enforceFocus = function () {
        };

        var table = $('#quotation').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Quotation Data</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax($('.dataTables_filter input').val().length > 0 ? SEARCH_QUOTATION_URL($('.dataTables_filter input').val(), id) : GET_QUOTATION_URL(id), {
                    type: 'GET',
                    data: {
                        pageNumber: (data.length + data.start) / data.length,
                        pageSize: data.length
                    },
                    success: function (res) {
                        let result = [];

                        res.data.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.totalRecords,
                            recordsFiltered: res.totalRecords,
                            data: result
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.quotationNumber;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${data.client.firstName} ${data.client.middleName ? data.client.middleName : ""} ${data.client.lastName}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.risks.length ? data.risks[0].vehicle.regNumber : "Not Set";
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.startDate).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.endDate).format("Do MMM, YYYY");
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return {
                            "Approved": `<span class="badge badge-success tx-white">${data.status}</span>`,
                            "Draft": `<span class="badge badge-secondary tx-white">${data.status}</span>`,
                            "Expired": `<span class="badge badge-danger tx-white">${data.status}</span>`
                        }[data.status];
                    },
                    "targets": 5
                }
            ]
        });

        $('#quotation tbody').on('click', 'tr', function () {
            var extract_data = table.row(this).data();
            props.history.push(`/quotation-details/${extract_data.raw.id}`);
        });

        return () => {
            $("#date_from").datepicker("destroy");
        }

    }, []);

    useEffect(() => {
        $(".vehicle-select2").select2({
            minimumInputLength: 0,
            maximumSelectionLength: 1,
            allowClear: true,
            placeholder: 'Type to search for reg#',
            ajax: {
                url: function (params) {
                    return params.term ? SEARCH_CLIENT_VEHICLE_URL(userId, params.term) : GET_CLIENT_VEHICLE_URL(userId);
                },
                dataType: 'json',
                type: "GET",
                data: function (query) {
                    return {
                        query: query
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: `${item.make} (${item.regNumber})`,
                                id: item.id
                            }
                        })
                    };
                }
            }
        });
    }, [updateVehicle]);

    $(function () {
        $('#date_from').datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            yearRange: "1800:+nn",
            changeMonth: true,
            changeYear: true,
            defaultDate: '+1w',
            numberOfMonths: 1,
            dateFormat: 'yy/mm/dd'
        });
    });

    const handleShowModal = () => {

        const addOn = $("#vehicle");

        $('.client-select2').select2({
            placeholder: 'Enter client name',
            minimumInputLength: 3,
            maximumSelectionLength: 1,
            ajax: {
                url: function (params) {
                    return SEARCH_CLIENT_URL(params.term);
                },
                dataType: 'json',
                type: "GET",
                data: function (query) {
                    return {
                        query: query
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: `${item.firstName} ${item.middleName ? item.middleName : ""} ${item.lastName} (${item.phoneNumber})`,
                                id: item.id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function (e) {
            setUserId(e.params.data.id);
            setUpdateVehicle(!updateVehicle);
            //Detect client is selected.
            addOn.prop('disabled', false);
        }).on('select2:unselect', function () {
            //Detect client is unselected.
            setUserId("");
            setUpdateVehicle(!updateVehicle);
            $(".vehicle-select2").empty().select2({
                placeholder: "Type to search for reg#"
            });
            addOn.prop('disabled', true);
        });

        addOn.prop('disabled', true);

        $("#basicInfo").parsley();
        $("#modalAddQuotation").modal({backdrop: 'static', keyboard: false}, "show");
    };

    const handleBasicSubmit = (e) => {
        e.preventDefault();
        $('#bank_info').parsley();
        $('#nav_basic .active').parent().next('li').find('a').trigger('click');
    };

    const onCloseModal = (id, form) => {
        let parsley = $(`#${form}`).parsley();
        $(':input', `#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".client-select2").empty().select2({
            placeholder: "Enter client name"
        });
        $(".vehicle-select2").empty().select2({
            placeholder: "Type to search for reg#"
        });
        $(".modal-backdrop").remove();
        $('#nav_basic a:first').tab('show');
    };

    const onSubmitQuotation = (e) => {

        e.preventDefault();
        setCreateLoading(true);
        const productId = Number($("#product").val());
        const gariCode = product.find((data) => data.id === productId)?.gariCode?.code;

        let raw = {
            "productId":productId,
            "startDate": new Date(),
            "quarters": $("#quarter").val(),
            "vehicleId": $("#vehicle").val().map((data) => {
                return data.replace(/[[\]]/g, '')
            })[0],
            "sourceOfBusiness": $("#sourceOfBusiness").val(),
            "clientId": $("#client").val().map((data) => {
                return data.replace(/[[\]]/g, '')
            })[0],
            "gariCode": gariCode,
            "sumInsured": $("#sumInsured").val(),
            "premiumType": $("#valueType").val(),
            "amount": $("#value").val(),
            "rate": $("#value").val()
        }


        if (gariCode === 'G-THP' || gariCode === 'G-ACO' ||'G-ACOT' ||'G-ACOF') {
            createThirdOrActOnlyQuotation(raw, id).then((res) => {
                handleResult(res);
            });
        } else {
            createQuotation(raw, id).then((res) => {
                handleResult(res);
            });
        }

    };

    const handleResult = (res) => {
        const options = {
            position: "top-right"
        };

        setCreateLoading(false);

        if (res.error) return cogoToast.error("Something went wrong.", options);

        onCloseModal("modalAddQuotation", "basicInfo");
        onCloseModal("modalAddQuotation", "bank_info");
        $('#quotation').DataTable().ajax.reload();
        cogoToast.success("Quotation successfully Added.", options);
    }

    const handlePrevious = () => {
        $('#nav_basic .active').parent().prev('li').find('a').trigger('click');
    };

    const onTypeChange = (e) => {
        setType(e.target.value);
    };

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Motor Quotations</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Motor Quotations</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"
                                onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New
                        </button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Motor Quotations</h6>
                                <div className="d-flex tx-18">
                                    <a href="" className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="quotation" className="table table-hover">
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="wd-20p">Quotation #</th>
                                        <th className="wd-20p">Client Name</th>
                                        <th className="wd-15p">Vehicle Reg#</th>
                                        <th className="wd-15p">Start Date</th>
                                        <th className="wd-15p">End Date</th>
                                        <th className="wd-15p">Status</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalAddQuotation" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal"
                               aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body"><a href="" role="button"
                                                           className="close pos-absolute t-15 r-15" data-dismiss="modal"
                                                           onClick={onCloseModal.bind(null, "modalAddQuotation", "basicInfo")}
                                                           aria-label="Close"><span aria-hidden="true">×</span></a><h4
                                className="tx-18 tx-sm-20 mg-b-2">Add Quotation</h4><p
                                className="tx-13 tx-color-02 mg-b-0">Create quotation for the vehicle</p></div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium nav-disabled">
                                        <li className="nav-item"><a href="#basic" className="nav-link active"
                                                                    data-toggle="tab"><i
                                            className="ion-md-information-circle"/> Vehicle Info</a></li>
                                        <li className="nav-item"><a href="#policy" className="nav-link"
                                                                    data-toggle="tab"><i
                                            className="ion-md-card"/> Policy</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="basic" className="tab-pane fade active show">
                                    <form id="basicInfo" className="parsley-style-1"
                                          onSubmit={handleBasicSubmit.bind()}>
                                        <div className="row row-sm">
                                            <div id="clientWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Client<span className="tx-danger">*</span></label>
                                                <select className="form-control client-select2" data-width="100%"
                                                        multiple="multiple"
                                                        data-parsley-class-handler="#clientWrapper"
                                                        data-parsley-errors-container="#clientWrapper"
                                                        id="client"
                                                        required>
                                                    <option value="0"></option>
                                                </select>
                                            </div>

                                            <div id="vehicleWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Vehicle Registration Number<span
                                                    className="tx-danger">*</span></label>
                                                <select className="form-control wd-438 vehicle-select2"
                                                        data-width="100%" multiple="multiple"
                                                        data-parsley-class-handler="#vehicleWrapper"
                                                        data-parsley-errors-container="#vehicleWrapper"
                                                        id="vehicle"
                                                        required>
                                                </select>
                                            </div>
                                        </div>
                                        {basicLoading ? <button disabled className="btn btn-brand-02 float-right"><span
                                                className="spinner-border spinner-border-sm mg-r-10" role="status"
                                                aria-hidden="true"/>Please Wait</button> :
                                            <button className="btn btn-brand-01 float-right btnNextFront">Next <i
                                                className="ion-md-arrow-forward"/></button>}
                                    </form>
                                </div>

                                <div id="policy" className="tab-pane fade">
                                    <form id="bank_info" className="parsley-style-1"
                                          onSubmit={onSubmitQuotation.bind()}>
                                        <div className="row row-sm">
                                            <div id="sourceWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Source Of Business<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="sourceOfBusiness" required>
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="Direct">Direct</option>
                                                    <option value="Broker">Broker</option>
                                                    <option value="Agent">Agent</option>
                                                </select>
                                            </div>

                                            <div id="quarterWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Product<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="product" required>
                                                    <option value="" disabled selected>Select</option>
                                                    {product.map((data) => <option
                                                        value={data.id}>{data.name} - {data.code}</option>)}
                                                </select>
                                            </div>

                                            <div id="dateWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Start Date<span className="tx-danger">*</span></label>
                                                <input type="text"
                                                       id="date_from"
                                                       className="form-control"
                                                       placeholder="Select Start Date"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#dateWrapper"
                                                       required
                                                       readOnly
                                                />
                                            </div>

                                            <div id="quarterWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Number of Quarter<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="quarter" required>
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                            </div>

                                            <div id="sumInsuredWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Sum Insured<span className="tx-danger">*</span></label>
                                                <input id="sumInsured" className="form-control"
                                                       placeholder="Enter sum amount"
                                                       type="number"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#sumInsuredWrapper" required/>
                                            </div>

                                            <div id="typeWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Type<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="valueType"
                                                        onChange={onTypeChange.bind(null)} required>
                                                    <option value="Amount">Amount</option>
                                                    <option value="Rate">Rate</option>
                                                </select>
                                            </div>

                                            <div id="numberWrapper" className="form-group parsley-input col-sm-12">
                                                <label>{capitalize(`${type} ${type === "Rate" ? "(%)" : ""}`)}<span
                                                    className="tx-danger">*</span></label>
                                                <input id="value" className="form-control"
                                                       placeholder="Enter value"
                                                       type="number"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#numberWrapper" required/>
                                            </div>

                                        </div>
                                        <hr/>
                                        {createLoading ? <button disabled className="btn btn-brand-02 float-right"><span
                                                className="spinner-border spinner-border-sm mg-r-10" role="status"
                                                aria-hidden="true"/>Please Wait</button> :
                                            <button className="btn btn-brand-01 float-right btnNextFront">Finish <i
                                                className="ion-md-arrow-forward"/></button>}
                                    </form>
                                    <button className="btn btn-dark float-left btnPrevious"
                                            onClick={handlePrevious.bind()}><i className="ion-md-arrow-back"/> Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MotorQuotation;
