import React, { useEffect, useState } from "react";
import {
  SEARCH_CLIENT_URL,
  GET_AGENT_CLIENTS_URL,
  GET_AGENT_COMMISSION_URL,
  GET_AGENT_POLICIES_URL,
  GET_AGENT_TRANSACTIONS_URL,
  GET_AGENT_LEADS_URL,
} from "../../../api";
import { getAgentDetails } from "../../../request";
import $ from "jquery";
import "components-jqueryui";
import "parsleyjs";
import "select2";
import "datatables.net";
import "datatables.net-responsive";
import moment from "moment";
import formatNumber from "../../../utils/formatNumber";
import { encodeQueryParameter } from "../../../utils/url";

const AgentDetails = (props) => {
  document.title = "Agent Profile";

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getAgentDetails(props.match.params.id).then((res) => {
      if (res.error) {
        return false;
      }
      setLoading(false);
      $("#gross-daily-premium").text(
        `K${formatNumber(res.payload.stats.grossDailyPremium.toFixed(2))}`
      );
      console.log("res.payload.stats.grossDailyPremium", res.payload);

      $("#gross-monthly-premium").text(
        `K${formatNumber(res.payload.stats.grossMonthlyPremium.toFixed(2))}`
      );
      $("#gross-yearly-premium").text(
        `K${formatNumber(res.payload.stats.grossYearlyPremium.toFixed(2))}`
      );

      $("#commission-daily-premium").text(
        `K${formatNumber(res.payload.stats.dailyCommission.toFixed(2))}`
      );
      $("#commission-monthly-premium").text(
        `K${formatNumber(res.payload.stats.monthlyCommission.toFixed(2))}`
      );
      $("#commission-yearly-premium").text(
        `K${formatNumber(res.payload.stats.yearlyCommission.toFixed(2))}`
      );

      $("#name").text(
        `${res.payload.agent.firstName} ${res.payload.agent.lastName}`
      );
      $("#total-commissions").text(
        res.payload.stats.totalCommissionEarned.toFixed(2)
      );
      $("#total-clients").text(res.payload.stats.totalClients);
      $("#total-policies").text(res.payload.stats.totalPolicies);
      $("#total-transactions").text(res.payload.stats.totalPolicies);
      $("#fullName").text(
        `${res.payload.agent.firstName} ${res.payload.agent.lastName}`
      );
      $("#gender").text(res.payload.agent.gender);
      $("#idNumber").text(res.payload.agent.idNumber);
      $("#idType").text(res.payload.idType);
      $("#phoneNumber").text(res.payload.agent.phoneNumber);
      $("#email").text(res.payload.agent.email);
      $("#address").text(res.payload.agent.physicalAddress || "Not Set");
      $("#accountCreated").text(
        moment(res.payload.agent.createdAt).format("Do MMM, YYYY")
      );

      let dt_commission = $("#dt-commission").DataTable({
        responsive: true,
        language: {
          processing:
            '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Commission Data</p>',
        },
        searching: true,
        lengthMenu: [[10], [10]],
        ordering: false,
        info: true,
        bFilter: false,
        processing: true,
        pageLength: 10,
        serverSide: true,
        ajax: function (data, callback) {
          // make a regular ajax request using data.start and data.length
          $.ajax(GET_AGENT_COMMISSION_URL(props.match.params.id), {
            type: "GET",
            data: {
              pageNumber: (data.length + data.start) / data.length,
              pageSize: data.length,
            },
            success: function (res) {
              let result = [];

              $("#total_commissions").text(formatNumber(res.totalRecords));

              res.data.map((data) => {
                result.push({
                  raw: data,
                });
              });

              callback({
                recordsTotal: res.totalRecords,
                recordsFiltered: res.totalRecords,
                data: result,
              });
            },
          });
        },
        columns: [
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
        ],
        columnDefs: [
          {
            render: function (data) {
              return data.insuranceCompany;
            },
            targets: 0,
          },
          {
            render: function (data) {
              return formatNumber(data.amountPaid.toFixed(2));
            },
            targets: 1,
          },
          {
            render: function (data) {
              return formatNumber(data.commissionRate.toFixed(2));
            },
            targets: 2,
          },
          {
            render: function (data) {
              return formatNumber(data.commissionAmount.toFixed(2));
            },
            targets: 3,
          },
          {
            render: function (data) {
              return moment(data.transactionDate).format("Do MMM, YYYY");
            },
            targets: 4,
          },
        ],
      });

      $("#dt-commission tbody").on("click", "tr", function () {
        var extract_data = dt_commission.row(this).data();

        if (extract_data === undefined) return null;

        $("#amountPaid").text(
          formatNumber(extract_data.raw.amountPaid.toFixed(2))
        );
        $("#clientName").text(extract_data.raw.clientName);
        $("#commissionAmount").text(
          formatNumber(extract_data.raw.commissionAmount.toFixed(2))
        );
        $("#commissionRate").text(
          formatNumber(extract_data.raw.commissionRate.toFixed(2))
        );
        $("#insuranceCompany").text(extract_data.raw.insuranceCompany);
        $("#transactionDate").text(
          moment(extract_data.raw.transactionDate).format("Do MMM, YYYY")
        );
        $("#vehicleRegNumber").text(extract_data.raw.vehicleRegNumber);
        $("#modalCommission").modal("show");
        return false;
      });

      var dt_client = $("#dt-client").DataTable({
        responsive: true,
        language: {
          processing:
            '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Client Data</p>',
        },
        searching: true,
        lengthMenu: [[10], [10]],
        ordering: false,
        info: true,
        bFilter: false,
        processing: true,
        pageLength: 10,
        serverSide: true,
        ajax: function (data, callback) {
          // make a regular ajax request using data.start and data.length
          $.ajax(
            $(".dataTables_filter input").val().length > 0
              ? SEARCH_CLIENT_URL($(".dataTables_filter input").val())
              : GET_AGENT_CLIENTS_URL(props.match.params.id),
            {
              type: "GET",
              data: {
                pageNumber: (data.length + data.start) / data.length,
                pageSize: data.length,
              },
              success: function (res) {
                let result = [];

                $("#total_clients").text(formatNumber(res.totalRecords));

                res.data.map((data) => {
                  result.push({
                    raw: data,
                  });
                });

                callback({
                  recordsTotal: res.totalRecords,
                  recordsFiltered: res.totalRecords,
                  data: result,
                });
              },
            }
          );
        },
        columns: [
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
        ],
        columnDefs: [
          {
            render: function (data) {
              return `${data.firstName} ${
                data.middleName ? data.middleName : ""
              } ${data.lastName}`;
            },
            targets: 0,
          },
          {
            render: function (data) {
              return data.gender;
            },
            targets: 1,
          },
          {
            render: function (data) {
              return data.phoneNumber ? data.phoneNumber : "Not Set";
            },
            targets: 2,
          },
          {
            render: function (data) {
              return data.email;
            },
            targets: 3,
          },
        ],
      });

      $("#dt-client tbody").on("click", "tr", function () {
        var extract_data = dt_client.row(this).data();
        props.history.push(`/client-details/${extract_data.raw.id}`);
      });

      var dt_policy = $("#dt-policies").DataTable({
        responsive: true,
        language: {
          processing:
            '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Policies Data</p>',
        },
        searching: false,
        lengthMenu: [[10], [10]],
        ordering: false,
        info: true,
        bFilter: false,
        processing: true,
        pageLength: 10,
        serverSide: true,
        ajax: function (data, callback) {
          // make a regular ajax request using data.start and data.length
          $.ajax(`${GET_AGENT_POLICIES_URL(props.match.params.id)}`, {
            type: "GET",
            data: {
              pageNumber: (data.length + data.start) / data.length,
              pageSize: data.length,
            },
            success: function (res) {
              let result = [];

              $("#total_policies").text(formatNumber(res.totalRecords));

              res.data.map((data) => {
                result.push({
                  raw: data,
                });
              });

              callback({
                recordsTotal: res.totalRecords,
                recordsFiltered: res.totalRecords,
                data: result,
              });
            },
          });
        },
        columns: [
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
        ],
        columnDefs: [
          {
            render: function (data) {
              return data.policyNumber;
            },
            targets: 0,
          },
          {
            render: function (data) {
              return `${data.client.firstName} ${
                data.client.middleName ? data.client.middleName : ""
              } ${data.client.lastName}`;
            },
            targets: 1,
          },
          {
            render: function (data) {
              return moment(data.startDate)
                .add(1, "days")
                .format("Do MMM, YYYY");
            },
            targets: 2,
          },
          {
            render: function (data) {
              return moment(data.endDate).format("Do MMM, YYYY");
            },
            targets: 3,
          },
          {
            render: function (data) {
              return moment(data.startDate).format("YYYY");
            },
            targets: 4,
          },
          {
            render: function (data) {
              return {
                Active: `<span class="badge badge-success tx-white">${data.status}</span>`,
                Expired: `<span class="badge badge-warning tx-white">${data.status}</span>`,
                Cancelled: `<span class="badge badge-danger">${data.status}</span>`,
                InClaim: `<span class="badge badge-warning">${data.status}</span>`,
                InReview: `<span class="badge badge-info">${data.status}</span>`,
                PayLater: `<span class="badge badge-secondary">${data.status}</span>`,
              }[data.status];
            },
            targets: 5,
          },
        ],
      });

      $("#dt-policies tbody").on("click", "tr", function () {
        var extract_data = dt_policy.row(this).data();
        props.history.push(
          `/policy-details/${extract_data.raw.clientId}/${encodeQueryParameter(
            extract_data.raw.id
          )}`
        );
      });

      $("#dt-transaction").DataTable({
        responsive: true,
        language: {
          processing:
            '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Transactions Data</p>',
        },
        searching: true,
        lengthMenu: [[10], [10]],
        ordering: false,
        info: true,
        bFilter: false,
        processing: true,
        pageLength: 10,
        serverSide: true,
        ajax: function (data, callback) {
          // make a regular ajax request using data.start and data.length
          $.ajax(GET_AGENT_TRANSACTIONS_URL(props.match.params.id), {
            type: "GET",
            data: {
              pageNumber: (data.length + data.start) / data.length,
              pageSize: data.length,
            },
            success: function (res) {
              let result = [];

              $("#total_transactions").text(formatNumber(res.totalRecords));

              res.data.map((data) => {
                result.push({
                  raw: data,
                });
              });

              callback({
                recordsTotal: res.totalRecords,
                recordsFiltered: res.totalRecords,
                data: result,
              });
            },
          });
        },
        columns: [
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },
        ],
        columnDefs: [
          {
            render: function (data) {
              return data.transactionNumber.split("@")[0];
            },
            targets: 0,
          },
          {
            render: function (data) {
              return data.amount;
            },
            targets: 1,
          },
          {
            render: function (data) {
              return data.paymentMethod;
            },
            targets: 2,
          },
          {
            render: function (data) {
              return {
                Success: `<span class="badge badge-success tx-white">${data.status}</span>`,
                Pending: `<span class="badge badge-warning tx-white">${data.status}</span>`,
                Failed: `<span class="badge badge-danger">${data.status}</span>`,
                Cancelled: `<span class="badge badge-secondary">${data.status}</span>`,
              }[data.status];
            },
            targets: 3,
          },
          {
            render: function (data) {
              return moment(data.createdAt).format("Do MMM, YYYY");
            },
            targets: 4,
          },
        ],
      });

      let dt_leads = $("#leads").DataTable({
        responsive: true,
        destroy: true,
        language: {
          processing:
            '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Leads Data</p>',
        },
        searching: false,
        lengthMenu: [[10], [10]],
        ordering: false,
        info: true,
        bFilter: false,
        processing: true,
        pageLength: 10,
        serverSide: true,
        ajax: function (data, callback) {
          // make a regular ajax request using data.start and data.length
          $.ajax(`${GET_AGENT_LEADS_URL(props.match.params.id)}`, {
            type: "GET",
            data: {
              pageNumber: (data.length + data.start) / data.length,
              pageSize: data.length,
            },
            success: function (res) {
              let result = [];
              $("#total_leads").text(formatNumber(res.totalRecords));
              res.data.map((data) => {
                result.push({
                  raw: data,
                });
              });

              callback({
                recordsTotal: res.totalRecords,
                recordsFiltered: res.totalRecords,
                data: result,
              });
            },
          });
        },
        columns: [
          { data: "raw" },
          { data: "raw" },
          { data: "raw" },

          { data: "raw" },
          { data: "raw" },
        ],
        columnDefs: [
          {
            render: function (data) {
              return `${data.firstName} ${data.lastName}`;
            },
            targets: 0,
          },

          {
            render: function (data) {
              return data.phoneNumber;
            },
            targets: 1,
          },
          {
            render: function (data) {
              return data.email;
            },
            targets: 2,
          },
          {
            render: function (data) {
              return data.leadStatus;
            },
            targets: 3,
          },

          {
            render: function (data) {
              return `
                    <nav class="nav nav-icon-only">
                      <button type="button" class="btn nav-link view_lead">
                        <i class="fa fa-eye"></i> View
                      </button>
                     
                    </nav>`;
            },
            targets: 4,
          },
        ],
      });

      $("#leads tbody").on("click", ".view_lead", function () {
        let extract_td = dt_leads.row($(this).closest("tr")).data();
        console.log("table row data", extract_td.raw.id);

        props.history.push(`/leads-details/${extract_td.raw.id}`);
      });
    });
  }, []);

  if (!loading) {
    return (
      <div className="content-body">
        <div className="container pd-x-0">
          <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <a href="#">Menu</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Agent</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Agent Details
                  </li>
                </ol>
              </nav>
              <h4 id="name" className="mg-b-0 tx-spacing--1">
                ---
              </h4>
            </div>
          </div>

          <div className="row row-xs">
            <div className="col-sm-6 col-lg-3">
              <div className="card card-body">
                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                  Total Commission
                </h6>
                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                  <h3
                    id="total-commissions"
                    className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                  >
                    -
                  </h3>
                </div>
                <a href="#">
                  <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">----</span>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
              <div className="card card-body">
                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                  Total Clients
                </h6>
                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                  <h3
                    id="total-clients"
                    className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                  >
                    -
                  </h3>
                </div>
                <span
                  id="date-current"
                  className="tx-11 tx-color-02 mg-b-0 mg-t-5"
                >
                  ---
                </span>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
              <div className="card card-body">
                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                  Total Policies
                </h6>
                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                  <h3
                    id="total-policies"
                    className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                  >
                    -
                  </h3>
                </div>
                <a href="#">
                  <span
                    id="month-current"
                    className="tx-11 tx-color-02 mg-b-0 mg-t-5"
                  >
                    ---
                  </span>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
              <div className="card card-body">
                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                  Total Transaction
                </h6>
                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                  <h3
                    id="total-transactions"
                    className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                  >
                    -
                  </h3>
                </div>
                <a href="#">
                  <span
                    id="month-current"
                    className="tx-11 tx-color-02 mg-b-0 mg-t-5"
                  >
                    ---
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div className="row row-xs">
            <div className="col-sm-12 col-lg-6 col-xl-6 mg-t-10">
              <div className="card mg-b-10">
                <div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                  <div className="d-sm-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-bar-chart-2"
                    >
                      <line x1="18" y1="20" x2="18" y2="10"></line>
                      <line x1="12" y1="20" x2="12" y2="4"></line>
                      <line x1="6" y1="20" x2="6" y2="14"></line>
                    </svg>
                    <h6 className="mg-b-1 mg-l-3 tx-pink">Gross Premium</h6>
                  </div>
                </div>

                <div className="card-body pd-y-30">
                  <div className="d-sm-flex">
                    <div className="media">
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">
                          Today
                        </h6>
                        <h4
                          className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0"
                          id="gross-daily-premium"
                        >
                          K0.00
                        </h4>
                      </div>
                    </div>
                    <div className="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                          Month
                        </h6>
                        <h4
                          className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0"
                          id="gross-monthly-premium"
                        >
                          K0.00
                        </h4>
                      </div>
                    </div>
                    <div className="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                          Year
                        </h6>
                        <h4
                          className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0"
                          id="gross-yearly-premium"
                        >
                          K0.00
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-lg-6 col-xl-6 mg-t-10">
              <div className="card mg-b-10">
                <div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                  <div className="d-sm-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-bar-chart-2"
                    >
                      <line x1="18" y1="20" x2="18" y2="10"></line>
                      <line x1="12" y1="20" x2="12" y2="4"></line>
                      <line x1="6" y1="20" x2="6" y2="14"></line>
                    </svg>
                    <h6 className="mg-b-1 mg-l-3 tx-purple">
                      Commission Earned
                    </h6>
                  </div>
                </div>

                <div className="card-body pd-y-30">
                  <div className="d-sm-flex">
                    <div className="media">
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">
                          Today
                        </h6>
                        <h4
                          className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0"
                          id="commission-daily-premium"
                        >
                          K0.00
                        </h4>
                      </div>
                    </div>
                    <div className="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                          Month
                        </h6>
                        <h4
                          className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0"
                          id="commission-monthly-premium"
                        >
                          K0.00
                        </h4>
                      </div>
                    </div>
                    <div className="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                          Year
                        </h6>
                        <h4
                          className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0"
                          id="commission-yearly-premium"
                        >
                          K0.00
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-xs">
            <div className="col-md-12 mg-t-10">
              <div className="nav-wrapper mg-b-20 tx-13">
                <div>
                  <ul
                    id="nav_basic"
                    className="nav nav-line nav-fill tx-medium"
                  >
                    <li className="nav-item">
                      <a
                        href="#customer"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        <i className="ion-ios-person" /> Agent Details
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#commission"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <i className="ion-ios-car" /> Commission{" "}
                        <span
                          id="total_commissions"
                          className="badge badge-light mg-l-2"
                        >
                          0
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#client" className="nav-link" data-toggle="tab">
                        <i className="ion-ios-document" /> Clients{" "}
                        <span
                          id="total_clients"
                          className="badge badge-light mg-l-5"
                        >
                          0
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#policy" className="nav-link" data-toggle="tab">
                        <i className="ion-ios-paper" /> Policies{" "}
                        <span
                          id="total_policies"
                          className="badge badge-light mg-l-5"
                        >
                          0
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#transaction"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <i className="ion-ios-card" /> Transactions{" "}
                        <span
                          id="total_transactions"
                          className="badge badge-light mg-l-5"
                        >
                          0
                        </span>
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <a
                        href="#leads_tab"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <i className="ion-ios-document" /> Leads{" "}
                        <span
                          id="total_leads"
                          className="badge badge-light mg-l-5"
                        >
                          0
                        </span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="tab-content">
                <div id="customer" className="tab-pane fade active show">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <p>
                        <strong>Full Name:</strong>{" "}
                        <span id="fullName">Blank</span>
                      </p>
                      <p>
                        <strong>Phone Number:</strong>{" "}
                        <span id="phoneNumber">Blank</span>
                      </p>
                      <p>
                        <strong>Gender:</strong> <span id="gender">Blank</span>
                      </p>
                      <p>
                        <strong>Mobile Number:</strong>{" "}
                        <span id="mobile">Blank</span>
                      </p>
                      <p>
                        <strong id="idType">NRC:</strong>{" "}
                        <span id="idNumber">Blank</span>
                      </p>
                      <p>
                        <strong>Email:</strong> <span id="email">Blank</span>
                      </p>
                      <p>
                        <strong>Physical Address:</strong>{" "}
                        <span id="address">Blank</span>
                      </p>
                      <p>
                        <strong>Account Created:</strong>{" "}
                        <span id="accountCreated">Blank</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div id="commission" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Commissions</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#dt-commissions").DataTable().ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="dt-commission"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-15p">Insurance</th>
                                <th className="wd-15p">Amount Paid</th>
                                <th className="wd-15p">Commission Rate</th>
                                <th className="wd-15p">Commission Amount</th>
                                <th className="wd-15p">Date Created</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="client" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Clients</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0"
                            >
                              <i className="icon ion-md-add"></i>
                            </a>
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#dt-clients").DataTable().ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="dt-client"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-20p">Client Name</th>
                                <th className="wd-20p">Gender</th>
                                <th className="wd-20p">Phone</th>
                                <th className="wd-20p">Email</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="policy" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Policies</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#policy_table").DataTable().ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="dt-policies"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-20p">Policy #</th>
                                <th className="wd-20p">Source Of Business</th>
                                <th className="wd-15p">Start Date</th>
                                <th className="wd-15p">End Date</th>
                                <th className="wd-10p">U/W Year</th>
                                <th className="wd-10p">Status</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="transaction" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Transactions</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#transaction_table")
                                  .DataTable()
                                  .ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="dt-transaction"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-25p">Transaction #</th>
                                <th className="wd-25p">Amount</th>
                                <th className="wd-25p">Payment Method</th>
                                <th className="wd-25p">Status</th>
                                <th className="wd-25p">Date Created</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="leads_tab" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Leads</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#policy_table").DataTable().ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="leads"
                            className="table table-hover "
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-20p">Names</th>
                                <th className="wd-20p">Phone Number</th>
                                <th className="wd-20p">Email Address</th>
                                <th className="wd-20p">Lead Status</th>
                                <th className="wd-50p">Actions</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="modalCommission"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered wd-sm-650"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                  <a
                    href="#"
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </a>
                  <div className="media-body">
                    <a
                      href=""
                      role="button"
                      className="close pos-absolute t-15 r-15"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </a>
                    <h4 className="tx-18 tx-sm-20 mg-b-2">Commission Detail</h4>
                    <p className="tx-13 tx-color-02 mg-b-0">
                      Customer commission full information.
                    </p>
                  </div>
                </div>
                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <p>
                        <strong>Customer Name:</strong>{" "}
                        <span id="clientName">Blank</span>
                      </p>
                      <p>
                        <strong>Amount Paid:</strong>{" "}
                        <span id="amountPaid">Blank</span>
                      </p>
                      <p>
                        <strong>Commission Amount:</strong>{" "}
                        <span id="commissionAmount">Blank</span>
                      </p>
                      <p>
                        <strong>Commission Rate:</strong>{" "}
                        <span id="commissionRate">Blank</span>
                      </p>
                      <p>
                        <strong>Insurance Company:</strong>{" "}
                        <span id="insuranceCompany">Blank</span>
                      </p>
                      <p>
                        <strong>vehicleRegNumber:</strong>{" "}
                        <span id="vehicleRegNumber">Blank</span>
                      </p>
                      <p>
                        <strong>Transaction Date:</strong>{" "}
                        <span id="transactionDate">Blank</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container d-flex justify-content-center ht-100p">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mg-t-10">Collecting Data</p>
        </div>
      </div>
    </div>
  );
};

export default AgentDetails;
