import React, {useEffect, useState} from 'react';
import $ from "jquery";
import moment from "moment";
import 'components-jqueryui';
import 'datatables.net';
import 'datatables.net-responsive';
import formatNumber from "../../../utils/formatNumber";
import {GET_BIDDER_URL, GET_OFFERS_URL} from "../../../api";
import cogoToast from "cogo-toast";
import {updateSalvage, getSalvage, selectWinner, rejectOffer, acceptOffer} from "../../../request";
import {useSelector} from "react-redux";

let _salvage = null;

const SalvageDetails = (props) => {

    const {id} = useSelector((state) => state.auth.user.user);

    const [viewPicture, setViewPicture] = useState(null);
    const [stateReady, setStateReady] = useState(null);
    const [salvage, setSalvage] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getSalvage(props.match.params.id).then((response) => {
            if(!response.error) {
                setSalvage(response.payload);
                _salvage = response.payload;
                setLoading(false);

                $("#txmake").text(response.payload.make);
                $("#txmodel").text(response.payload.model);
                $("#txchassisNumber").text(response.payload.chassisNumber);
                $("#txcolor").text(response.payload.color);
                $("#txregNumber").text(response.payload.regNumber);
                $("#txyearOfManufacture").text(response.payload.yearOfManufacture);
                $("#txmileage").text(formatNumber(response.payload.mileage));
                $("#txsalvageType").text(response.payload.salvageType);
                $("#txstartingPrice").text(formatNumber(response.payload.startingPrice));
                $("#txminIncrease").text(formatNumber(response.payload.minIncrease));
                $("#txlocation").text(response.payload.location);
                $("#txdamageDescription").text(response.payload.damageDescription);
                $("#txstartTime").text(moment(response.payload.startTime).format("Do MMM, YYYY"));
                $("#txendTime").text(moment(response.payload.endTime).format("Do MMM, YYYY"));
                $("#total-gallery").text(response.payload.imageUrls.length);

                var table = $('#dt-bidder').DataTable({
                    responsive: true,
                    "language": {
                        "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Data</p>'
                    },
                    "searching": true,
                    "lengthMenu": [[10], [10]],
                    ordering: false,
                    info: true,
                    bFilter: false,
                    processing: true,
                    pageLength: 10,
                    serverSide: true,
                    ajax: function(data, callback) {
                        // make a regular ajax request using data.start and data.length
                        $.ajax(response.payload.salvageType === "Offer" ? GET_OFFERS_URL(props.match.params.id) : GET_BIDDER_URL(props.match.params.id), {
                            type: 'GET',
                            data: {
                                pageNumber: (data.length + data.start) / data.length,
                                pageSize: data.length
                            },
                            success : function(res) {
                                let result = [];

                                $("#total-bidder").text(formatNumber(res.totalRecords));

                                res.data.map((data) => {
                                    result.push({
                                        raw: data
                                    });
                                });

                                callback({
                                    recordsTotal: res.totalRecords,
                                    recordsFiltered: res.totalRecords,
                                    data: result
                                });
                            }
                        });
                    },
                    "columns": [
                        {"data": "raw"},
                        {"data": "raw"},
                        {"data": "raw"},
                        {"data": "raw"},
                        {"data": "raw"},
                        {"data": "raw"}
                    ],
                    "columnDefs": [
                        {
                            "render": function (data) {
                                return `${data.client.firstName} ${data.client.middleName ? data.client.middleName : ""} ${data.client.lastName}`;
                            },
                            "targets": 0
                        },
                        {
                            "render": function (data) {
                                return data.client.phoneNumber;
                            },
                            "targets": 1
                        },
                        {
                            "render": function (data) {
                                return formatNumber(data.amount);
                            },
                            "targets": 2
                        },
                        {
                            "render": function (data) {
                                return _salvage.salvageType === "Bid" ? "---" : {
                                    "Accepted" : `<span class="badge badge-success tx-white">${data.status}</span>`,
                                    "Expired" : `<span class="badge badge-warning tx-white">${data.status}</span>`,
                                    "Cancelled" : `<span class="badge badge-danger">${data.status}</span>`
                                }[data.status];
                            },
                            "targets": 3
                        },
                        {
                            "render": function (data) {
                                return moment(data.createdAt).format("Do MMM, YYYY");
                            },
                            "targets": 4
                        },
                        {
                            "render": function () {
                                return _salvage.salvageStatus === "Available" ? `<nav class="nav nav-icon-only"><a href="#" class="nav-link accept"><i class="fa fa-check"></i> Accept</a> ${_salvage.salvageType === "Offer" ? "<a href=\"#\" class=\"nav-link reject\"><i class=\"fa fa-ban\"></i> Reject</a>" : ""}</nav>` : "No Actions";
                            },
                            "targets": 5
                        }
                    ]
                });

                table.on('click', '.accept', function() {
                    let extract_td = table.row($(this).closest('tr')).data();

                    let {hide} = cogoToast.loading('Please wait... Selecting Winner.', {position: "top-right", hideAfter: 0});

                    _salvage.salvageType === "Offer" ? acceptOffer(extract_td.raw.id).then((response) => {
                        hide();
                        if(response.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
                        cogoToast.success("Offer Accepted.", {position: "top-right"});
                    }) : selectWinner(extract_td.raw.salvageId).then((response) => {
                        hide();
                        if(response.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
                        cogoToast.success("Winner Accepted.", {position: "top-right"});
                    });

                    return false;
                });

                table.on('click', '.reject', function() {
                    let extract_td = table.row($(this).closest('tr')).data();

                    let {hide} = cogoToast.loading('Please wait... Rejecting.', {position: "top-right", hideAfter: 0});
                    rejectOffer(extract_td.raw.id).then((response) => {
                        hide();
                        if(response.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
                        cogoToast.success("Rejected Successfully.", {position: "top-right"});
                    });

                    return false;
                });
            }
        });

    }, []);

    $(function () {
        $('#date_from').datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            yearRange: "1800:+nn",
            changeMonth: true,
            changeYear: true,
            minDate: 0,
            numberOfMonths: 1,
            dateFormat: 'yy/mm/dd'
        });

        $('#date_to').datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            yearRange: '2022:3000',
            changeMonth: true,
            changeYear: true,
            minDate: 0,
            numberOfMonths: 1,
            dateFormat: 'yy/mm/dd'
        });
    });

    const viewModalPicture = (data) => {
        setViewPicture(data);
        $("#modalShowPicture").modal("show");
    }

    const onUpdateSalvage = (e) => {
        e.preventDefault();
        setStateReady(true);

        let data = {
            "make": $("#make").val(),
            "model": $("#model").val(),
            "chassisNumber": $("#chassisNumber").val(),
            "color": $("#color").val(),
            "regNumber": $("#regNumber").val(),
            "yearOfManufacture": $("#yearOfManufacture").val(),
            "mileage": $("#mileage").val(),
            "salvageType": "Offer",
            "startingPrice": $("#startingPrice").val(),
            "minIncrease": $("#minIncrease").val(),
            "location": $("#location").val(),
            "damageDescription": $("#damageDescription").val(),
            "startTime": new Date(salvage.startTime),
            "endTime": new Date(salvage.endTime),
            "imageUrls": salvage.imageUrls
        }

        updateSalvage(data, salvage.id, id).then((res) => {
            const options = {
                position: "top-right",
                hideAfter: 2
            };

            setStateReady(false);

            if(res.error) return cogoToast.error("Something went wrong.", options);

            onCloseModal("modalUpdateSalvage", "basicSalvage");

            cogoToast.success("Salvage successfully Updated.", options);

        });
    }

    const handleShowModal = () => {

        $("#make").val("BMW");
        $("#model").val(salvage.model);
        $("#chassisNumber").val(salvage.chassisNumber);
        $("#color").val(salvage.color);
        $("#regNumber").val(salvage.regNumber);
        $("#yearOfManufacture").val(salvage.yearOfManufacture);
        $("#mileage").val(salvage.mileage);
        $("#startingPrice").val(salvage.startingPrice);
        $("#minIncrease").val(salvage.minIncrease);
        $("#location").val(salvage.location);
        $("#damageDescription").val(salvage.damageDescription);

        $('#basicSalvage').parsley();
        $("#modalUpdateSalvage").modal({backdrop: 'static', keyboard: false}, "show");
    };

    const onCloseModal = (id, form) => {
        let parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        parsley.reset();
        parsley.destroy();
        $(`#${id}`).modal("hide");
        $(".modal-backdrop").remove();
    };

    if(!loading) {
        return (
            <div className="content-body">
                <div className="container pd-x-0">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                            <li className="breadcrumb-item"><a href="#">Menu</a></li>
                            <li className="breadcrumb-item"><a href="#">Salvage</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Salvage Details</li>
                        </ol>
                    </nav>
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-t-20 mg-lg-b-25 mg-xl-b-30">
                        <div className="media">
                            <div className="wd-150 ht-100 bg-ui-04 rounded d-flex align-items-center justify-content-center">
                                <img src={salvage.imageUrls[0]} className="img-fluid rounded-5 img-fit-cover"/>
                            </div>
                            <div className="media-body pd-l-25 my-auto">
                                <h5 className="mg-b-5">{salvage.make} {salvage.model}</h5>
                                <p className="mg-b-3"><span
                                    className="tx-medium tx-color-02">Reg Number: {salvage.regNumber}</span><br/>chassis Number: {salvage.chassisNumber}</p>
                                {salvage.salvageType === "Bid" ? <span className="tx-white badge badge-primary">{salvage.salvageType}</span> : <span className="tx-white badge badge-warning">{salvage.salvageType}</span>}
                            </div>
                        </div>
                        {/*<div className="d-none d-md-block">*/}
                        {/*    <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-pencil"></i> Edit Salvage</button>*/}
                        {/*</div>*/}
                    </div>

                    <div className="row row-xs">
                        <div className="col-md-12 mg-t-10">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                        <li className="nav-item"><a href="#salvage" className="nav-link active" data-toggle="tab"><i className="ion-ios-person"/> Salvage Details</a></li>
                                        <li className="nav-item"><a href="#bidder" className="nav-link" data-toggle="tab"><i className="ion-ios-paper"/> {salvage.salvageType === "Bid" ? "Bidders" : "Offers"} <span id="total-bidder" className="badge badge-light mg-l-5">0</span></a></li>
                                        <li className="nav-item"><a href="#gallery" className="nav-link" data-toggle="tab"><i className="ion-ios-document"/> Gallery <span id="total-gallery" className="badge badge-light mg-l-5">0</span></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="salvage" className="tab-pane fade active show">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>Make:</strong> <span id="txmake">Blank</span></p>
                                            <p><strong>Chassis Number:</strong> <span id="txchassisNumber">Blank</span></p>
                                            <p><strong>Color:</strong> <span id="txcolor">Blank</span></p>
                                            <p><strong>Registration Number:</strong> <span id="txregNumber">Blank</span></p>
                                            <p><strong>Year Of Manufacture:</strong> <span id="txyearOfManufacture">Blank</span></p>
                                            <p><strong>Mileage:</strong> <span id="txmileage">Blank</span></p>
                                            <p><strong>Salvage Type:</strong> <span id="txsalvageType">Blank</span></p>
                                            <p><strong>Starting Price:</strong> <span id="txstartingPrice">Blank</span></p>
                                            <p><strong>Min Increase:</strong> <span id="txminIncrease">Blank</span></p>
                                            <p><strong>Location:</strong> <span id="txlocation">Blank</span></p>
                                            <p><strong>Damage Description:</strong> <span id="txdamageDescription">Blank</span></p>
                                            <p><strong>Start Time:</strong> <span id="txstartTime">Blank</span></p>
                                            <p><strong>End Time:</strong> <span id="txendTime">Blank</span></p>
                                        </div>
                                    </div>
                                </div>

                                <div id="bidder" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">{salvage.salvageType === "Bid" ? "Bidders" : "Offers"}</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#dt-bidder').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="dt-bidder" className="table table-hover" style={{width: "100%"}}>
                                                        <thead className="thead-light">
                                                        <tr>
                                                            <th className="wd-20p">Client Name</th>
                                                            <th className="wd-20p">Phone Number</th>
                                                            <th className="wd-20p">Amount</th>
                                                            <th className="wd-20p">Status</th>
                                                            <th className="wd-20p">Date</th>
                                                            <th className="wd-20p">Actions</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="gallery" className="tab-pane fade">
                                    <div className="row row-sm">
                                        {salvage.imageUrls.map((result, index) => {
                                            return (
                                                <a href="javascript:void(0)" onClick={viewModalPicture.bind(null, result)} key={index} className="col-sm-4 col-md-3 col-lg-4 col-xl-3 mg-b-20">
                                                    <div className="card card-profile">
                                                        <img src={result} alt="" className="img-fluid ht-170 rounded-5"/>
                                                    </div>
                                                </a>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalUpdateSalvage" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close" onClick={onCloseModal.bind(null, "modalUpdateSalvage", "basicSalvage")}>
                                            <span aria-hidden="true">&times;</span>
                                        </a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2">Update Salvages</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="basicSalvage" className="parsley-style-1" onSubmit={onUpdateSalvage.bind(null)} encType="multipart/form-data">
                                    <div id="regWrapper" className="form-group parsley-input">
                                        <label>Registration #<span className="tx-danger">*</span></label>
                                        <input id="regNumber" className="form-control"
                                               placeholder="Enter registration number"
                                               type="text"
                                               autoComplete="off"
                                               data-parsley-class-handler="#regWrapper" required/>
                                    </div>

                                    <div id="chassiWrapper" className="form-group parsley-input">
                                        <label>Chassis Number<span className="tx-danger">*</span></label>
                                        <input id="chassisNumber" className="form-control"
                                               placeholder="Enter chassis number"
                                               type="text"
                                               autoComplete="off"
                                               data-parsley-class-handler="#chassiWrapper" required/>
                                    </div>

                                    <div id="makeWrapper" className="form-group parsley-input">
                                        <label>Make<span className="tx-danger">*</span></label>
                                        <input id="make" className="form-control"
                                               placeholder="Enter make"
                                               type="text"
                                               autoComplete="off"
                                               data-parsley-class-handler="#makeWrapper" required/>
                                    </div>

                                    <div className="row">
                                        <div id="colorWrapper" className="form-group parsley-input col-sm-6">
                                            <label>Color<span className="tx-danger">*</span></label>
                                            <input id="color" className="form-control"
                                                   placeholder="Enter color"
                                                   type="text"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#colorWrapper" required/>
                                        </div>

                                        <div id="modelWrapper" className="form-group parsley-input col-sm-6">
                                            <label>Model<span className="tx-danger">*</span></label>
                                            <input id="model" className="form-control"
                                                   placeholder="Enter model"
                                                   type="text"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#modelWrapper" required/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div id="yearWrapper" className="form-group parsley-input col-sm-6">
                                            <label>Year Of Manufacture<span className="tx-danger">*</span></label>
                                            <input id="yearOfManufacture" className="form-control"
                                                   placeholder="Enter year of manufacture"
                                                   type="number"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#yearWrapper" required/>
                                        </div>

                                        <div id="mileageWrapper" className="form-group parsley-input col-sm-6">
                                            <label>Mileage<span className="tx-danger">*</span></label>
                                            <input id="mileage" className="form-control"
                                                   placeholder="Enter mileage"
                                                   type="number"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#mileageWrapper" required/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div id="startingPriceWrapper" className="form-group parsley-input col-sm-6">
                                            <label>Starting Price<span className="tx-danger">*</span></label>
                                            <input id="startingPrice" className="form-control"
                                                   placeholder="Enter starting price"
                                                   type="number"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#startingPriceWrapper" required/>
                                        </div>

                                        <div id="minWrapper" className="form-group parsley-input col-sm-6">
                                            <label>Min Increase<span className="tx-danger">*</span></label>
                                            <input id="minIncrease" className="form-control"
                                                   placeholder="Enter min increase"
                                                   type="number"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#minWrapper" required/>
                                        </div>
                                    </div>

                                    <div id="locationWrapper" className="form-group parsley-input">
                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Location</label>
                                        <input id="location" type="text" className="form-control"
                                               placeholder="Enter location address"
                                               autoComplete="off"
                                               data-parsley-class-handler="#locationWrapper"
                                               required
                                        />
                                    </div>

                                    <div id="descriptionWrapper" className="form-group parsley-input">
                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Description</label>
                                        <textarea id="damageDescription" type="text" rows="5" className="form-control" placeholder="Provide a description" data-parsley-class-handler="#descriptionWrapper" required></textarea>
                                    </div>

                                    {stateReady ? <button className="btn btn-brand-02 btn-block" disabled><span className="spinner-border spinner-border-sm mg-r-10" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-success btn-block"><i className="fa fa-plus"/> Update Salvage</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalShowPicture" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content rounded-10">
                            <img className="img-fluid rounded-5" src={viewPicture} alt="cover-art"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="content-body">
            <div className="container d-flex justify-content-center ht-100p">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="mg-t-10">Collecting Data</p>
                </div>
            </div>
        </div>
    )
};

export default SalvageDetails;
