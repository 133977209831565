import axios from "axios";
import {GET_INSURANCE_ALL_PRODUCT, UPLOAD_GENERAL_DOCUMENT_URL} from "../api";
import {PRODUCT} from "../stores/organization";

export const getAllProduct = (id) => {
    return function(dispatch) {
        return axios.get(GET_INSURANCE_ALL_PRODUCT(id)).then((response) => {
            dispatch(PRODUCT({product: response.data.data}));
        }).catch((error) => {
            console.log("Something went wrong");
        });
    }
};

export const uploadGeneralDocument = (raw, config) => {
    return axios.post(UPLOAD_GENERAL_DOCUMENT_URL, raw, config).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
};
