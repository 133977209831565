import React, { useEffect } from 'react';
import {GET_POLICIES_URL} from '../../../api';
import {getOrganization, getStatistics} from '../../../request';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {encodeQueryParameter} from "../../../utils/url";
import { Link } from 'react-router-dom';

const RiskCategory = (props) => {

    document.title = "Product Risk Category";

    const {id} = useSelector((state) => state.auth.user.user);
    const {riskCategory} = useSelector((state) => state.organization.riskCategory);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrganization());
    }, []);

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Product</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Risk Category</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    {riskCategory.map((data) => {
                        return(
                            <div className="col-6 col-sm-4 col-md-3 col-xl-2">
                                <Link to={`risk-category-list/${data.id}`} className="card card-file">
                                    <div className="card-file-thumb bg-white tx-danger">
                                        <img className="ht-50" src={data.iconUrl} />
                                    </div>
                                    <div className="card-body">
                                        <h6><a href="" className="link-02">{data.name}</a></h6>
                                        <span>{data.description}</span>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
};

export default RiskCategory;
