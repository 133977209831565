import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import {SIGNIN_URL, GET_ALL_INSURANCE_URL} from '../api';
import jwt_decode from 'jwt-decode';
import {SET_AUTH_USER, UNSET_USER} from '../stores/auth';
import errorHandler from "../utils/errorHandler";
import insurance from "../data/insurances.json";
import cogoToast from "cogo-toast";

const option = {
    position: "top-right",
    hideAfter: 2
};

//Login - Auth Token
export const loginUser = (userData) => {
    return function(dispatch) {
        //Save to localStorage
        return axios.get(GET_ALL_INSURANCE_URL).then((response) => {
            console.log(response.data.data);
            let findInsurance = response.data.data.find((data) => data.companyEmail === userData.email);
            // console.log(findInsurance);
            if(findInsurance !== undefined) {
                dispatch(SET_AUTH_USER({user: findInsurance, isAuthenticated: true}));
            } else {
                cogoToast.error("Email or password is in correct.", option);
                return false;
            }
        }).catch((e) => {
            cogoToast.error("Server connection failed.", option);
            return false;
        });
    }
};

export const logoutUser = () => {
    return function(dispatch) {
        //Remove toke from localStorage
        localStorage.clear();

        //Remove auth header for future requests
        setAuthToken(false);

        //Set current user to {} which will set isAuthenticated to false
        dispatch(UNSET_USER());
    }
};
