import axios from "axios";
import {
    ADD_INSURANCE_SALVAGE_URL,
    DELETE_SALVAGE_URL,
    GET_SALVAGES_URL,
    UPDATE_SALVAGE_URL,
    GET_SALVAGE_URL,
    SELECT_WINNER_URL,
    REJECT_OFFER_URL,
    ACCEPT_OFFER_URL
} from "../api";
import errorHandler from "../utils/errorHandler";

export const getSalvages = (id) => {
    return axios.get(GET_SALVAGES_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true,
            payload: error
        }
    });
};

export const deleteSalvage = (id) => {
    return axios.delete(DELETE_SALVAGE_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
}

export const addSalvage = (raw, insuranceId) => {
    return axios.post(ADD_INSURANCE_SALVAGE_URL(insuranceId), raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        console.log(error.response.data);
        return {
            error: true,
            payload: error.response.data
        }
    });
}

export const updateSalvage = (raw, id, insuranceId) => {
    return axios.put(UPDATE_SALVAGE_URL(id, insuranceId), raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        console.log(error.response.data);
        return {
            error: true,
            payload: error.response.data
        }
    });
}

export const getSalvage = (id) => {
    return axios.get(GET_SALVAGE_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
}

export const selectWinner = (id) => {
    return axios.post(SELECT_WINNER_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
}

export const acceptOffer = (id) => {
    return axios.post(ACCEPT_OFFER_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
}

export const rejectOffer = (id) => {
    return axios.post(REJECT_OFFER_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        console.log(error.response.data);
        return {
            error: true,
            payload: error.response.data
        }
    });
}