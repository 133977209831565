import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import 'components-jqueryui';
import 'parsleyjs';
import 'select2';
import 'datatables.net';
import 'datatables.net-responsive';
import {addDiscount, addExtension, addLimitOfLiability, getQuotation, makePayment} from "../../../request";
import moment from "moment";
import truncate from "../../../utils/trucate";
import {Link} from 'react-router-dom';
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";
import {capitalize} from "../../../utils/capitalize";
import formatNumber from "../../../utils/formatNumber";

const QuotationDetails = (props) => {

    document.title = "Quotation Details";

    const {discount} = useSelector((state) => state.organization.discount);
    const {extension} = useSelector((state) => state.organization.extension);

    const {product} = useSelector((state) => state.organization.product);

    const [initialLoading, setInitialLoading] = useState(true);
    const [status, setStatus] = useState("Approved");
    const [vehicleStatus, setVehicleStatus] = useState("HasPolicy");
    const [quotationStatus, setQuotationStatus] = useState("Approved");
    const [policyWording, setPolicyWording] = useState(null);
    const [policyClauses, setPolicyClauses] = useState(null);
    const [premium, setPremium] = useState({});
    const [addon, setAddon] = useState([]);
    const [insuranceDiscount, setInsuranceDiscount] = useState([]);
    const [insuranceExtension, setInsuranceExtension] = useState([]);
    const [insuranceLimitLiability, setInsuranceLimitLiability] = useState([]);
    const [quotation, setQuotation] = useState(null);
    const [basicLoading, setBasicLoading] = useState(false);
    const [addOnLoading, setAddOnLoading] = useState(false);
    const [submitType, setSubmitType] = useState("discount");
    const [payment, setPayment] = useState([
        {name: "Cash", code: "Cash"},
        {name: "Cheque", code: "Cheque"},
        {name: "ETF", code: "ETF"},
        {name: "Pay Later", code: "PAYLATER"}
    ]);
    const [type, setType] = useState("amount");
    const [selectedMethod, setSelectedMethod] = useState("Select Payment");
    const [selectedName, setSelectedName] = useState("Select Payment");
    const [perils, setPerils] = useState([]);

    useEffect(() => {
        getQuotation(props.match.params.id).then((response) => {
            if(!response.error) {
                let find_product = product.find((data) => {return data.id === response.payload.product.id});

                console.log(find_product);

                setInitialLoading(false);
                setQuotation(response.payload);
                setStatus(response.payload.status);
                setInsuranceDiscount(response.payload.risks[0].discounts || []);
                setInsuranceExtension(response.payload.risks[0].extensions || []);
                setInsuranceLimitLiability(response.payload.risks[0].limitsOfLiability || []);
                setVehicleStatus(response.payload.risks[0].vehicle.status);
                setQuotationStatus(response.payload.status);
                setPolicyWording(find_product.policyWordings);
                setPolicyClauses(find_product.policyClauses);
                setPerils(find_product.perils);

                //Populate quotation details
                $(".quotationNumber").text(response.payload.quotationNumber);
                $("#startDate").text(moment(response.payload.client.startDate).format("Do MMM, YYYY"));
                $("#endDate").text(moment(response.payload.risks[0].endDate).format("Do MMM, YYYY"));
                $("#premiumType").text(response.payload.premiumType);
                $("#status").text(response.payload.status);
                $("#sourceOfBusiness").text(response.payload.sourceOfBusiness);
                $("#expiryDate").text(moment(response.payload.client.expiryDate).format("Do MMM, YYYY"));
                $("#premium").text(response.payload.product.premiumRates ? response.payload.product.premiumRates.premium : "Not Set");
                $("#levy").text(response.payload.product.premiumRates ? response.payload.product.premiumRates.levy : "Not Set");
                $("#maximumLimit").text(response.payload.product.premiumRates ? response.payload.product.premiumRates.maximumLimit : "Not Set");
                $("#minimumLimit").text(response.payload.product.premiumRates ? response.payload.product.premiumRates.minimumLimit : "Not Set");

                //Populate client details
                $("#type").val(response.payload.client.clientType);
                $("#accountType").text(response.payload.client.clientType);
                $("#fullName").text(`${response.payload.client.firstName} ${response.payload.client.middleName ? response.payload.client.middleName : ""} ${response.payload.client.lastName}`);
                $("#gender").text(response.payload.client.gender);
                $("#mobile").text(response.payload.client.phoneNumber);
                $("#dob").text(moment(response.payload.client.dateOfBirth).format("Do MMM, YYYY"));
                $("#email").text(response.payload.client.email);
                $("#idType").text(`${response.payload.client.idType}:`);
                $("#idNumber").text(response.payload.client.idNumber || "Not Set");
                $("#address").text(response.payload.client.physicalAddress || "Not Set");
                $("#accountCreated").text(moment(response.payload.client.createdAt).format("Do MMM, YYYY - HH:mm"));

                // $("#wordingsDocument").text("View Document").attr("href", `https://clientsapi.gari.dev/documents/${props.match.params._id}/certificate`).attr('target','_blank');
                // $("#wordingsDocument").text("View Document").attr("href", `https://clientsapi.gari.dev/documents/${props.match.params._id}/certificate`).attr('target','_blank');
                //
                //Populate risks details
                setPremium({
                    sumInsured: formatNumber(parseFloat(response.payload.risks[0].sumInsured).toFixed(2)),
                    premiumLevy: formatNumber(parseFloat(response.payload.risks[0].premiumLevy).toFixed(2)),
                    basicPremium: formatNumber(parseFloat(response.payload.risks[0].basicPremium).toFixed(2)),
                    netPremium: formatNumber(parseFloat(response.payload.risks[0].netPremium).toFixed(2)),
                    discount: formatNumber(calculateTotal(response.payload.risks[0].discounts || []).toFixed(2)),
                    extension: formatNumber(calculateTotal(response.payload.risks[0].extensions || []).toFixed(2))
                });

                $("#riskStartDate").text(moment(response.payload.risks[0].startDate).format("Do MMM, YYYY - HH:mm"));
                $("#riskEndDate").text(moment(response.payload.risks[0].endDate).format("Do MMM, YYYY - HH:mm"));
                $("#expiryQuarter").text(response.payload.risks[0].expiryQuarter ? response.payload.risks[0].expiryQuarter : "Not Set");


                //Populate vehicle details
                $("#regNumber").text(response.payload.risks[0].vehicle.regNumber);
                $("#make").text(response.payload.risks[0].vehicle.make);
                $("#model").text(response.payload.risks[0].vehicle.model);
                $("#engineNumber").text(response.payload.risks[0].vehicle.engineNumber);
                $("#chassisNumber").text(response.payload.risks[0].vehicle.chassisNumber);
                $("#cubicCapacity").text(response.payload.risks[0].vehicle.cubicCapacity);
                $("#color").text(response.payload.risks[0].vehicle.color);
                $("#createdAt").text(moment(response.payload.risks[0].vehicle.createdAt).format("Do MMM, YYYY"));
                $("#updatedAt").text(moment(response.payload.risks[0].vehicle.updatedAt).format("Do MMM, YYYY"));

                //Populate product details
                $("#prdouctName").text(response.payload.product.name);
                $("#productCode").text(response.payload.product.code);
                $("#productDescription").text(response.payload.product.description);
                $("#policyNumberPrefix").text(response.payload.product.policyNumberPrefix);
                $("#claimNumberPrefix").text(response.payload.product.claimNumberPrefix);
                $("#productPremium").text(formatNumber(find_product.premiumRates.premium));
                $("#productLevy").text(formatNumber(find_product.premiumRates.levy));
                $("#productMaximumLimit").text(formatNumber(find_product.premiumRates.maximumLimit));
                $("#productMinimumLimit").text(formatNumber(find_product.premiumRates.minimumLimit));
                $("#gariCode").text(find_product.gariCode.code || "Not Set");
            }
        });
    }, []);

    const calculateTotal = (arr) => {
        return arr.map(function (data) {
            return data.amount;
        }).reduce(function (acc, score) {
            return acc + score;
        }, 0);
    };

    const handleMakePayment = () => {
        const options = {
            position: "top-right"
        };

        if(selectedMethod === "Select Payment") return cogoToast.error("Please, select payment method before you approve.", options);

        setBasicLoading(true);

        const raw = {
            "clientId": quotation.clientId,
            "productId": quotation.productId,
            "quotationId": quotation.id,
            "paymentMethod": selectedMethod,
            "accountNumber": "N/A",
            "amount": quotation.risks[0].sumInsured
        }

        makePayment(raw).then((result) => {
            setBasicLoading(false);
            if(result.error) return cogoToast.error("Something went wrong.", options);

            cogoToast.success("Payment Successfully Made.", options);
            window.location.reload();
        });
    };

    const checkStatus = (type) => {
        if(type === "vehicle") {
            return {
                "HasPolicy" : <span className="badge badge-success tx-white">{vehicleStatus}</span>,
                "InClaim" : <span className="badge badge-warning tx-white">{vehicleStatus}</span>,
                "NoPolicy" : <span className="badge badge-danger tx-white">{vehicleStatus}</span>
            }[vehicleStatus];
        } else {
            return {
                "Approved" : <span className="badge badge-success tx-white">{quotationStatus}</span>,
                "Draft" : <span className="badge badge-secondary tx-white">{quotationStatus}</span>,
                "Expired" : <span className="badge badge-danger tx-white">{quotationStatus}</span>
            }[quotationStatus];
        }
    };

    const handlePaymentMethod= (data) => {
        setSelectedMethod(data.code);
        setSelectedName(data.name)
    };

    const onCloseModal = (id, form) => {
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        $(`#${form}`).parsley().reset();
        $(".modal-backdrop").remove();
        $("#valueType").val("amount");
    };

    const onSubmitAddOn = (e) => {
        e.preventDefault();

        if(submitType === "extension") {
            let extension = {
                "quotationId": props.match.params.id,
                "extensionTypeId": $("#discountType").val(),
                "type": capitalize($("#valueType").val()),
                "amount": $("#value").val(),
                "rate": $("#value").val()
            };

            setAddOnLoading(true)
            addExtension(extension).then((result) => {
                setAddOnLoading(false);
                if(result.error) {
                    return cogoToast.error("Something went wrong");
                }

                getQuotation(props.match.params.id).then((response) => {
                    setInsuranceExtension(response.payload.risks[0].extensions || []);
                    setPremium({
                        sumInsured: parseFloat(response.payload.risks[0].sumInsured).toFixed(2),
                        premiumLevy: parseFloat(response.payload.risks[0].premiumLevy).toFixed(2),
                        basicPremium: parseFloat(response.payload.risks[0].basicPremium).toFixed(2),
                        netPremium: parseFloat(response.payload.risks[0].netPremium).toFixed(2),
                        discount: calculateTotal(response.payload.risks[0].discounts || []),
                        extension: calculateTotal(response.payload.risks[0].extensions || [])
                    });
                });

                onCloseModal("modalAddon", "addon");
            });

            return false;
        }

        let discount = {
            "quotationId": props.match.params.id,
            "discountTypeId": $("#discountType").val(),
            "type": capitalize($("#valueType").val()),
            "amount": $("#value").val(),
            "rate": $("#value").val()
        };

        setAddOnLoading(true)
        addDiscount(discount).then((result) => {
            setAddOnLoading(false);
            if(result.error) {
                return cogoToast.error("Something went wrong");
            }

            getQuotation(props.match.params.id).then((response) => {
                setInsuranceDiscount(response.payload.risks[0].discounts || []);
                setPremium({
                    sumInsured: parseFloat(response.payload.risks[0].sumInsured).toFixed(2),
                    premiumLevy: parseFloat(response.payload.risks[0].premiumLevy).toFixed(2),
                    basicPremium: parseFloat(response.payload.risks[0].basicPremium).toFixed(2),
                    netPremium: parseFloat(response.payload.risks[0].netPremium).toFixed(2),
                    discount: calculateTotal(response.payload.risks[0].discounts || []),
                    extension: calculateTotal(response.payload.risks[0].extensions || [])
                });
            });

            onCloseModal("modalAddon", "addon");
        });
    };

    const onSubmitLimitOfLiabilty = (e) => {
        e.preventDefault();

        let data = {
            "quotationId": props.match.params.id,
            "amount": $("#limitValue").val(),
            "type": $("#limitType").val()
        };

        setAddOnLoading(true)
        addLimitOfLiability(data).then((result) => {
            setAddOnLoading(false);
            if(result.error) {
                return cogoToast.error("Something went wrong");
            }

            getQuotation(props.match.params.id).then((response) => {
                setInsuranceLimitLiability(response.payload.risks[0].limitsOfLiability || []);
            });

            onCloseModal("modalLimitLiability", "limitLiabilty");
        });
    };

    const handleAddOnModal = (type) => {
        type === "discount" ? setAddon(discount) : setAddon(extension);
        setSubmitType(type);
        $('#addon').parsley();

        $('#sumInsured').text(premium.sumInsured);
        $('#premiumLevy').text(premium.premiumLevy);
        $('#premiumBasic').text(premium.basicPremium);
        $('#premiumNet').text(premium.netPremium);
        $('#premiumDiscount').text(premium.discount);
        $('#premiumExtension').text(premium.extension);

        $("#modalAddon").modal("show");
    };

    const handleLimitLiabiltyModal = (selected) => {
        $('#limitLiability').parsley();
        $('#limitType').val(selected)
        $("#modalLimitLiability").modal("show");
    }

    const onPremiumRateChange = (event) => {
        if(submitType === "discount") {
            $("#premiumDiscount").text(event.target.value !== "" ? (parseFloat(premium.discount) + parseFloat(event.target.value)).toFixed(2) : premium.discount);
            $("#premiumBasic").text((premium.basicPremium - event.target.value).toFixed(2));
            $("#premiumNet").text(event.target.value !== "" ? (parseFloat(event.target.value) + parseFloat(premium.premiumLevy) + parseInt(premium.basicPremium)).toFixed(2) : premium.premiumLevy);
            return false;
        }

        $("#premiumExtension").text(event.target.value !== "" ? (parseFloat(premium.extension) + parseFloat(event.target.value)).toFixed(2) : premium.extension);
        $("#premiumBasic").text(event.target.value !== "" ? (parseFloat(premium.basicPremium) + parseFloat(event.target.value)).toFixed(2) : premium.basicPremium);
        $("#premiumNet").text(event.target.value !== "" ? (parseInt(event.target.value) + parseInt(premium.premiumLevy) + parseInt(premium.basicPremium)).toFixed(2) : premium.premiumLevy);
    };

    const onTypeChange = (e) => {
        setType(e.target.value)
    };

    if(!initialLoading) {
        const renderDiscountInfo = (id) => {
            let findDiscount = discount.find((data) => data.id === id);
            return(
                <div>
                    <p className="tx-color-03 mg-b-0">Name: {findDiscount.name}</p>
                    <p className="tx-color-03 mg-b-0">Description: {findDiscount.description}</p>
                </div>
            )
        };

        const renderExtensionInfo = (id) => {
            let findExtension = extension.find((data) => data.id === id);
            return(
                <div>
                    <p className="tx-color-03 mg-b-0">Name: {findExtension.name}</p>
                    <p className="tx-color-03 mg-b-0">Description: {findExtension.description}</p>
                </div>
            )
        };

        return (
            <div className="content-body">
                <div className="container pd-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                    <li className="breadcrumb-item"><a href="#">Quotation</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Quotation Details</li>
                                </ol>
                            </nav>
                            <h4 className="quotationNumber mg-b-0 tx-spacing--1">---</h4>
                        </div>
                        {(() => {
                            if(status !== "Approved") {
                                return(
                                    <div className="d-none d-md-block">
                                        <button className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {selectedName}
                                        </button>
                                        <div className="dropdown-menu tx-13">
                                            {payment.map((result) => {
                                                return(
                                                    <Link key={result} className="dropdown-item" to="#" onClick={handlePaymentMethod.bind(null, result)}>{result.name}</Link>
                                                )
                                            })}
                                        </div>
                                        {basicLoading ? <button disabled className="btn btn-sm pd-x-15 btn-brand-02 mg-l-5"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleMakePayment.bind()}><i className="ion-md-checkmark-circle"></i> Approve</button>}
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })()}
                    </div>

                    <div className="row row-xs">
                        <div className="col-md-12 mg-t-10">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                        <li className="nav-item"><a href="#basic " className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle"/> Quotations</a></li>
                                        <li className="nav-item"><a href="#client" className="nav-link" data-toggle="tab"><i className="ion-md-person"/> Client</a></li>
                                        <li className="nav-item"><a href="#risks" className="nav-link" data-toggle="tab"><i className="ion-md-book"/> Risks</a></li>
                                        <li className="nav-item"><a href="#products" className="nav-link" data-toggle="tab"><i className="ion-ios-checkmark-circle"/> Products</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="basic" className="tab-pane fade active show">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>Quotation #:</strong> <span className="quotationNumber">Blank</span></p>
                                            <p><strong>Initial Date:</strong> <span id="startDate">Blank</span></p>
                                            <p><strong>End Date:</strong> <span id="endDate">Blank</span></p>
                                            <p><strong>Status:</strong> {checkStatus("quotation")}</p>
                                            <p><strong>Source Of Business:</strong> <span id="sourceOfBusiness">Blank</span></p>
                                            <p><strong>Expiry Date:</strong> <span id="expiryDate">Blank</span></p>

                                            <hr/>
                                            <h5 className="tx-spacing--1 tx-color-03">Premium Calculation</h5>
                                            <p><strong>Sum Insured:</strong> <span id="sumInsured">{premium.sumInsured}</span></p>
                                            <p><strong>Premium Type:</strong> <span id="premiumType">---</span></p>
                                            <p><strong>Premium Amount:</strong> <span id="premiumValue">---</span></p>
                                            <p><strong>Extension:</strong> <span id="netExtension">{premium.extension}</span></p>
                                            <p><strong>Discount:</strong> <span id="netDiscount">{premium.discount}</span></p>
                                            <p><strong>Premium Levy:</strong> <span id="premiumLevy">{premium.premiumLevy}</span></p>
                                            <p><strong>Basic Premium:</strong> <span id="basicPremium">{premium.basicPremium}</span></p>
                                            <p><strong>Net Premium:</strong> <span id="netPremium">{premium.netPremium}</span></p>

                                            <hr/>
                                            <h5 className="tx-spacing--1 tx-color-03">Discounts {status !== "Approved" ? <a href="javascript:void(0)" onClick={handleAddOnModal.bind(null, "discount")}><i className="ion-md-add-circle"/></a> : ""} </h5>
                                            {insuranceDiscount.length ? insuranceDiscount.map((data) => {
                                                return <div className="card card-body mg-t-15">
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <h6 className="mg-b-10">{data.type}: {data.type === "Amount" ? `K${data.amount}` : `${data.rate}%`}</h6>
                                                            {renderDiscountInfo(data.discountTypeId)}
                                                        </div>
                                                    </div>
                                                </div>
                                            }) : <p className="tx-danger">No Discount</p>}

                                            <hr/>
                                            <h5 className="tx-spacing--1 tx-color-03">Extension {status !== "Approved" ? <a href="javascript:void(0)" onClick={handleAddOnModal.bind(null, "extension")}><i className="ion-md-add-circle"/></a> : ""}</h5>
                                            {insuranceExtension.length ? insuranceExtension.map((data) => {
                                                return <div className="card card-body mg-t-15">
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <h6 className="mg-b-10">{data.type}: {data.type === "Amount" ? `K${data.amount}` : `${data.rate}%`}</h6>
                                                            {renderExtensionInfo(data.extensionTypeId)}
                                                        </div>
                                                    </div>
                                                </div>
                                            }) : <p className="tx-danger">No Extensions</p>}

                                            <hr/>
                                            <h5 className="tx-spacing--1 tx-color-03">Limits Of Liability</h5>
                                            <div className="card card-body mg-t-15">
                                            {insuranceLimitLiability.length ? insuranceLimitLiability.map((data) => {
                                                return <div className="media mb-3">
                                                        <div className="media-body">
                                                            <p className="tx-color-03 mg-b-0">Name: {data.liabilityType} <a href="javascript:void(0)" onClick={handleLimitLiabiltyModal.bind(null, data.liabilityType)}><i className="ion-md-add-circle"/></a></p>
                                                            <h6 className="mg-b-1">Amount: K{formatNumber(data.amount)}</h6>
                                                        </div>
                                                    </div>
                                            }) : <p className="tx-danger">No Limit Of Liability</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="client" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>Account Type:</strong> <span id="accountType">Blank</span></p>
                                            <p><strong>Full Name:</strong> <span id="fullName">Blank</span></p>
                                            <p><strong>DOB:</strong> <span id="dob">Blank</span></p>
                                            <p><strong>Gender:</strong> <span id="gender">Blank</span></p>
                                            <p><strong>Mobile Number:</strong> <span id="mobile">Blank</span></p>
                                            <p><strong id="idType">Passport:</strong> <span id="idNumber">Blank</span></p>
                                            <p><strong>Email:</strong> <span id="email">Blank</span></p>
                                            <p><strong>Physical Address:</strong> <span id="address">Blank</span></p>
                                            <p><strong>Account Created:</strong> <span id="accountCreated">Blank</span></p>
                                        </div>
                                    </div>
                                </div>

                                <div id="risks" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <h5 className="tx-spacing--1 tx-color-03">Vehicle</h5>
                                            <p><strong>Registration #:</strong> <span id="regNumber">Blank</span></p>
                                            <p><strong>Make:</strong> <span id="make">Blank</span></p>
                                            <p><strong>Model:</strong> <span id="model">Blank</span></p>
                                            <p><strong>Engine Number:</strong> <span id="engineNumber">Blank</span></p>
                                            <p><strong>Chassis Number:</strong> <span id="chassisNumber">Blank</span></p>
                                            <p><strong>Cubic Capacity:</strong> <span id="cubicCapacity">Blank</span></p>
                                            <p><strong>Color:</strong> <span id="color">Blank</span></p>
                                            <p><strong>Created At:</strong> <span id="createdAt">Blank</span></p>
                                            <p><strong>Updated At:</strong> <span id="updatedAt">Blank</span></p>
                                            <p><strong>Status:</strong> {checkStatus("vehicle")}</p>
                                        </div>
                                    </div>
                                </div>

                                <div id="products" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>Name:</strong> <span id="prdouctName">Blank</span></p>
                                            <p><strong>code:</strong> <span id="productCode">Blank</span></p>
                                            <p><strong>description:</strong> <span id="productDescription">Blank</span></p>
                                            <p><strong>Policy Number Prefix:</strong> <span id="policyNumberPrefix">Blank</span></p>
                                            <p><strong>Claim Number Prefix:</strong> <span id="claimNumberPrefix">Blank</span></p>
                                            <p><strong>gariCode:</strong> <span id="gariCode">Blank</span></p>

                                            <hr/>
                                            <h5 className="tx-spacing--1 tx-color-03">Premium Rate</h5>
                                            <p><strong>Premium:</strong> <span id="productPremium">Blank</span></p>
                                            <p><strong>Levy:</strong> <span id="productLevy">Blank</span></p>
                                            <p><strong>Maximum Limit:</strong> <span id="productMaximumLimit">Blank</span></p>
                                            <p><strong>Minimum Limit:</strong> <span id="productMinimumLimit">Blank</span></p>

                                            <hr/>
                                            <h5 className="tx-spacing--1 tx-color-03">Policy Wordings</h5>
                                            <p className="mb-4"><strong>Wordings Document:</strong> <a href="javascript:void(0)" id="certificate">No Document</a></p>

                                            <hr/>
                                            <h5 className="tx-spacing--1 tx-color-03">Policy Clauses</h5>
                                            <p className="mb-4"><strong>Clauses Document:</strong> <a href="javascript:void(0)" id="certificate">No Document</a></p>

                                            <hr/>
                                            <h5 className="tx-spacing--1 tx-color-03">Perils</h5>
                                            {(() => {
                                                if(perils.length !== 0) {
                                                    return(
                                                        <div>
                                                            {perils.map((data) => {
                                                                return(
                                                                    <div className="card card-body mg-t-15">
                                                                        <div className="media">
                                                                            <div className="media-body">
                                                                                <h6 className="mg-b-10">{data.name} ({data.type})</h6>
                                                                                <p className="tx-color-03 mg-b-0">{truncate(data.description, 500, "...")}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}

                                                            <p className="text-center mg-t-15 tx-brand-02 tx-sm-12">--- End of Perils ---</p>
                                                        </div>
                                                    )
                                                } else {
                                                    return(
                                                        <p className="tx-danger"><span className="badge badge-danger">No Perils</span></p>
                                                    )
                                                }
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalAddon" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered wd-md-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-ksy-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="javascript:void(0)" role="button" className="close pos-absolute t-15 r-15" onClick={onCloseModal.bind(null, "modalAddon", "addon")}><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Add {submitType === "discount" ? "Discount" : "Extension"}</h4><p className="tx-13 tx-color-02 mg-b-0">Fill the information below.</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="addon" className="parsley-style-1" data-parsley-validate noValidate onSubmit={onSubmitAddOn.bind()}>
                                        <div className="row row-sm">
                                            <div className="col-md-4">
                                                <h5 className="tx-spacing--1 tx-color-03">Premium<br/> Calculation</h5>
                                                <p><strong>Sum Insured</strong> <span id="sumInsured">{premium.sumInsured}</span></p>
                                                <p><strong>Premium Levy</strong> <span id="premiumLevy">{premium.premiumLevy}</span></p>
                                                <p><strong>Basic Premium</strong> <span id="premiumBasic">{premium.basicPremium}</span></p>
                                                <p><strong>Net Premium</strong> <span id="premiumNet">{premium.netPremium}</span></p>
                                                <p><strong>Discount</strong> <span id="premiumDiscount">{premium.discount}</span></p>
                                                <p><strong>Extension</strong> <span id="premiumExtension">{premium.extension}</span></p>

                                            </div>
                                            <div className="col-md-8 my-auto">
                                                <div id="discountTypeWrapper" className="form-group parsley-input col-sm-12">
                                                    <label>Select {submitType === "discount" ? "Discount" : "Extension"}<span className="tx-danger">*</span></label>
                                                    <select className="custom-select" id="discountType" required>
                                                        <option value="" disabled selected>Select</option>
                                                        {addon.map((data) => <option value={data.id}>{data.name}</option>)}
                                                    </select>
                                                </div>

                                                <div id="typeWrapper" className="form-group parsley-input col-sm-12">
                                                    <label>Type<span className="tx-danger">*</span></label>
                                                    <select className="custom-select" id="valueType" onChange={onTypeChange.bind(null)} required>
                                                        <option value="amount">Amount</option>
                                                        <option value="rate">Rate</option>
                                                    </select>
                                                </div>

                                                <div id="numberWrapper" className="form-group parsley-input col-sm-12">
                                                    <label>{capitalize(type)}<span className="tx-danger">*</span></label>
                                                    <input id="value" className="form-control"
                                                           placeholder="Enter value"
                                                           type="number"
                                                           autoComplete="off"
                                                           onChange={onPremiumRateChange.bind()}
                                                           data-parsley-class-handler="#numberWrapper" required/>
                                                </div>
                                            </div>
                                        </div>
                                        {addOnLoading ? <button disabled className="btn btn-brand-02 btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-brand-02 btn-block mt-2"><i className="ion-md-add"/> {submitType === "discount" ? "Add Discount" : "Add Extension"}</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalLimitLiability" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="javascript:void(0)" role="button" className="close pos-absolute t-15 r-15" onClick={onCloseModal.bind(null, "modalLimitLiability", "limitLiability")}><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Limit Of Liability</h4><p className="tx-13 tx-color-02 mg-b-0">Fill the amount below.</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="limitLiability" className="parsley-style-1" data-parsley-validate noValidate onSubmit={onSubmitLimitOfLiabilty.bind()}>
                                        <div className="row row-sm">
                                            <div id="_typeWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Type<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="limitType" required disabled>
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="DeathInjuryPerPerson">Death And Injury Per Person</option>
                                                    <option value="DeathInjuryPerEvent">Death And In jury Per Event</option>
                                                    <option value="PropertyDamage">Property Damage</option>
                                                </select>
                                            </div>

                                            <div id="_numberWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Amount<span className="tx-danger">*</span></label>
                                                <input id="limitValue" className="form-control"
                                                       placeholder="Enter value"
                                                       type="number"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#_numberWrapper" required/>
                                            </div>
                                        </div>
                                        {addOnLoading ? <button disabled className="btn btn-brand-02 btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-brand-02 btn-block mt-2"><i className="ion-md-add"/> Update Amount</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="content-body">
            <div className="container d-flex justify-content-center ht-100p">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="mg-t-10">Collecting Data</p>
                </div>
            </div>
        </div>
    )
};

export default QuotationDetails;
