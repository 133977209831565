import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import feather from "feather-icons";
import { getOrganization, logoutUser } from "../../request";
import PerfectScrollbar from "perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth.user);
  const [statistics, set_statistics] = useState("nav-item");
  const [client, set_client] = useState("nav-item");
  const [product, set_product] = useState("nav-item");
  const [addon, set_addon] = useState("nav-item");
  const [addon_sub, set_addon_sub] = useState("nav-item");
  const [claim, set_claim] = useState("nav-item");
  const [extension, set_extension] = useState("nav-item");
  const [discount, set_discount] = useState("nav-item");
  const [loading, set_loading] = useState("nav-item");
  const [motor, set_motor] = useState("nav-item");
  const [organization, set_organization] = useState("nav-item");
  const [motor_sub, set_motor_sub] = useState("nav-item with-sub");
  const [policy, set_policy] = useState("nav-item");
  const [endorsement, set_endorsement] = useState("nav-item");
  const [rtsa_upload, set_rtsa_upload] = useState("nav-item");
  const [policy_sub, set_policy_sub] = useState("nav-item with-sub");
  const [finance_receipt, set_finance_receipt] = useState("nav-item");
  const [finance_sub, set_finance_sub] = useState("nav-item with-sub");
  const [salvage, set_salvage] = useState("nav-item");
  const [report, set_report] = useState("nav-item");
  const [report_sub, set_report_sub] = useState("nav-item with-sub");
  const [gari_agent, set_gari_agent] = useState("nav-item");

  const [product_motor, set_product_motor] = useState("nav-item");
  const [product_risk_category, set_product_risk_category] =
    useState("nav-item");
  const [product_sub, set_product_sub] = useState("nav-item with-sub");

  const onLogoutClick = (event) => {
    event.preventDefault();
    dispatch(logoutUser());
  };

  useEffect(() => {
    dispatch(getOrganization(auth.user.id));
  }, []);

  const handleActiveLink = (data) => {
    set_statistics("nav-item");
    set_client("nav-item");
    set_product("nav-item");
    set_addon("nav-item");
    set_addon_sub("nav-item with-sub");
    set_claim("nav-item");
    set_motor("nav-item");
    set_motor_sub("nav-item with-sub");
    set_policy("nav-item");
    set_endorsement("nav-item");
    set_rtsa_upload("nav-item");
    set_policy_sub("nav-item with-sub");
    set_finance_receipt("nav-item");
    set_finance_sub("nav-item with-sub");
    set_salvage("nav-item");
    set_report("nav-item");
    set_report_sub("nav-item with-sub");
    set_gari_agent("nav-item");

    set_product_motor("nav-item");
    set_product_risk_category("nav-item");
    set_product_sub("nav-item with-sub");
    set_discount("nav-item");
    set_extension("nav-item");
    set_loading("nav-item");
    switch (data) {
      case "dashboard":
        set_statistics("nav-item active");
        break;
      case "client":
        set_client("nav-item active");
        break;
      case "claim":
        set_claim("nav-item active");
        break;
      case "motor":
        set_motor_sub("nav-item with-sub active");
        set_motor("nav-item active");
        break;
      case "policy":
        set_policy_sub("nav-item with-sub active");
        set_policy("nav-item active");
        break;
      case "endorsement":
        set_policy_sub("nav-item with-sub active");
        set_endorsement("nav-item active");
        break;
      case "rtsa_upload":
        set_policy_sub("nav-item with-sub active");
        set_rtsa_upload("nav-item active");
        break;
      case "receipt":
        set_finance_sub("nav-item with-sub active");
        set_finance_receipt("nav-item active");
        break;
      case "salvage":
        set_salvage("nav-item active");
        break;
      case "report":
        set_report_sub("nav-item with-sub active");
        set_report("nav-item active");
        break;
      case "discount":
        set_addon_sub("nav-item with-sub active");
        set_discount("nav-item active");
        break;
      case "extension":
        set_addon_sub("nav-item with-sub active");
        set_extension("nav-item active");
        break;
      case "loading":
        set_addon_sub("nav-item with-sub active");
        set_loading("nav-item active");
        break;
      case "product_motor":
        set_product_sub("nav-item with-sub active");
        set_product_motor("nav-item active");
        break;
      case "product_risk_category":
        set_product_sub("nav-item with-sub active");
        set_product_risk_category("nav-item active");
        break;
      default:
        set_statistics("nav-item");
        break;
    }
  };

  useEffect(() => {
    handleActiveLink("default");

    $(function () {
      "use strict";
      feather.replace();

      const asideBody = new PerfectScrollbar(".aside-body", {
        suppressScrollX: true,
      });

      if ($(".aside-backdrop").length === 0) {
        $("body").append('<div class="aside-backdrop"></div>');
      }

      var mql = window.matchMedia("(min-width:992px) and (max-width: 1199px)");

      function doMinimize(e) {
        if (e.matches) {
          $(".aside").addClass("minimize");
        } else {
          $(".aside").removeClass("minimize");
        }

        asideBody.update();
      }

      mql.addListener(doMinimize);
      doMinimize(mql);

      $(".aside-menu-link").on("click", function (e) {
        e.preventDefault();

        if (window.matchMedia("(min-width: 992px)").matches) {
          $(this).closest(".aside").toggleClass("minimize");
        } else {
          $("body").toggleClass("show-aside");
        }

        asideBody.update();
      });

      $(".nav-aside .with-sub").on("click", ".nav-link", function (e) {
        e.preventDefault();

        $(this).parent().siblings().removeClass("show");
        $(this).parent().toggleClass("show");

        asideBody.update();
      });

      $("body").on("mouseenter", ".minimize .aside-body", function (e) {
        console.log("e");
        $(this).parent().addClass("maximize");
      });

      $("body").on("mouseleave", ".minimize .aside-body", function (e) {
        $(this).parent().removeClass("maximize");

        asideBody.update();
      });

      $("body").on("click", ".aside-backdrop", function (e) {
        $("body").removeClass("show-aside");
      });
    });
  }, []);

  return (
    <aside className="aside aside-fixed">
      <div className="aside-header">
        <a href="#" className="aside-logo">
          Gari<span>Motor</span>
        </a>
        <a href="#" className="aside-menu-link">
          <i data-feather="menu"></i>
          <i data-feather="x"></i>
        </a>
      </div>
      <div className="aside-body">
        <div className="aside-loggedin">
          <div className="aside-loggedin-user">
            <a
              href="#loggedinMenu"
              className="d-flex align-items-center justify-content-between mg-b-2"
              data-toggle="collapse"
            >
              <h6 className="tx-semibold mg-b-0">{auth.user.name}</h6>
              <i data-feather="chevron-down"></i>
            </a>
            <p className="tx-color-03 tx-12 mg-b-0">{auth.user.role}</p>
          </div>
          <div className="collapse" id="loggedinMenu">
            <ul className="nav nav-aside mg-b-0">
              <li className="nav-item">
                <Link
                  to="#"
                  onClick={onLogoutClick.bind()}
                  className="nav-link"
                >
                  <i data-feather="log-out"></i>
                  <span>Sign Out</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <ul className="nav nav-aside">
          <li className="nav-label">Menu</li>
          <li className={statistics}>
            <Link
              to="/dashboard"
              onClick={handleActiveLink.bind(null, "dashboard")}
              className="nav-link"
            >
              <i data-feather="grid"></i> <span>Dashboard</span>
            </Link>
          </li>
          <li className={client}>
            <Link
              to="/client"
              onClick={handleActiveLink.bind(null, "client")}
              className="nav-link"
            >
              <i data-feather="users"></i> <span>Client</span>
            </Link>
          </li>
          <li className={motor_sub}>
            <a href="" className="nav-link">
              <i data-feather="file-text"></i> <span>Quotation</span>
            </a>
            <ul>
              <li className={motor}>
                <Link
                  to="/motor-quotation"
                  onClick={handleActiveLink.bind(null, "motor")}
                >
                  Motor
                </Link>
              </li>
            </ul>
          </li>
          <li className={policy_sub}>
            <a href="" className="nav-link">
              <i data-feather="edit-3"></i> <span>Underwriting</span>
            </a>
            <ul>
              <li className={policy}>
                <Link
                  to="/policy"
                  onClick={handleActiveLink.bind(null, "policy")}
                >
                  Policy
                </Link>
              </li>
              <li className={endorsement}>
                <Link
                  to="/endorsement"
                  onClick={handleActiveLink.bind(null, "endorsement")}
                >
                  Endorsement
                </Link>
              </li>
              <li className={rtsa_upload}>
                <Link
                  to="/rtsa-upload"
                  onClick={handleActiveLink.bind(null, "rtsa_upload")}
                >
                  RTSA Upload
                </Link>
              </li>
            </ul>
          </li>

          <li className={claim}>
            <Link
              to="/claim"
              onClick={handleActiveLink.bind(null, "claim")}
              className="nav-link"
            >
              <i data-feather="alert-circle"></i> <span>Claims</span>
            </Link>
          </li>

          <li className={finance_sub}>
            <a href="" className="nav-link">
              <i data-feather="briefcase"></i> <span>Finance</span>
            </a>
            <ul>
              <li className={finance_receipt}>
                <Link
                  to="/receipt"
                  onClick={handleActiveLink.bind(null, "receipt")}
                >
                  Receipt
                </Link>
              </li>
            </ul>
          </li>

          <li className={salvage}>
            <Link
              to="/salvage"
              onClick={handleActiveLink.bind(null, "salvage")}
              className="nav-link"
            >
              <i data-feather="tool"></i> <span>Salvage</span>
            </Link>
          </li>

          <li className={report_sub}>
            <a href="" className="nav-link">
              <i data-feather="trending-up"></i> <span>Report</span>
            </a>
            <ul>
              <li className={report}>
                <Link
                  to="/report"
                  onClick={handleActiveLink.bind(null, "report")}
                >
                  Report
                </Link>
              </li>
            </ul>
          </li>
          <li className={gari_agent}>
            <Link
              to="/agent"
              onClick={handleActiveLink.bind(null, "agent")}
              className="nav-link"
            >
              <i data-feather="target"></i> <span>Agents</span>
            </Link>
          </li>
          <li className="nav-label mg-t-25">Settings</li>
          <li className={product_sub}>
            <a href="" className="nav-link">
              <i data-feather="list"></i> <span>Product</span>
            </a>
            <ul>
              <li className={product_motor}>
                <Link
                  to="/product"
                  onClick={handleActiveLink.bind(null, "product_motor")}
                >
                  Motor
                </Link>
              </li>
              <li className={product_risk_category}>
                <Link
                  to="/risk-category"
                  onClick={handleActiveLink.bind(null, "product_risk_category")}
                >
                  Risk Category
                </Link>
              </li>
            </ul>
          </li>
          <li className={addon_sub}>
            <a href="" className="nav-link">
              <i data-feather="plus-circle"></i> <span>Addons</span>
            </a>
            <ul>
              <li className={discount}>
                <Link
                  to="/discount"
                  onClick={handleActiveLink.bind(null, "discount")}
                >
                  Discount
                </Link>
              </li>
              <li className={extension}>
                <Link
                  to="/extension"
                  onClick={handleActiveLink.bind(null, "extension")}
                >
                  Extension
                </Link>
              </li>
            </ul>
          </li>
          <li className={organization}>
            <Link
              to="/organization"
              onClick={handleActiveLink.bind(null, "organization")}
              className="nav-link"
            >
              <i data-feather="alert-circle"></i> <span>Organization</span>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Header;
